<div class="card border mb-3">
  <div class="card-body">
    <form #form="ngForm" class="form-validate">

      <div class="row mb-3">

        <label for="province" class="col-xl-2 col-form-label text-end">เขต</label>
        <div class="col-xl-3">
          <select class="form-select col-lg-5" id="zone" name="zone" [(ngModel)]="param.zone">
            <option selected hidden [ngValue]="undefined">ทั้งหมด</option>
            <option *ngFor="let zone of zones" [ngValue]="zone"> {{ zone?.name }}</option>
          </select>
        </div>

        <label for="province" class="col-xl-2 col-form-label text-end">จังหวัด</label>
        <div class="col-xl-3">
          <province-autocomplete id="province" name="province" [restrictZoneId]="param?.zone?.id"
                                 [(ngModel)]="param.province"></province-autocomplete>
        </div>

      </div>

      <div class="row mb-3">
        <label for="year" class="col-xl-2 col-form-label text-end">ปีงบประมาณ</label>
        <div class="col-xl-3">
          <select class="form-select col-lg-5" id="year" name="year" [(ngModel)]="param.year"
                  (change)="selectYear()">
            <option selected hidden [ngValue]="undefined">กรุณาเลือก</option>
            <option *ngFor="let item of years" [ngValue]="item"> {{ item }}</option>
          </select>
        </div>

        <label for="hmainop" class="col-xl-2 col-form-label text-end">หน่วยบริการแม่ข่าย (HMAIN_OP)</label>
        <div class="col-xl-3">
          <input type="text" readonly *ngIf="!param.year" value="กรุณาระบุปีงบประมาณ"
                 class="form-control-plaintext" id="hmainop-readonly">
          <transfer-hmain-op-autocomplete *ngIf="param.year" [(ngModel)]="param.hospitalTransferYearDto"
                                          [provinceId]="param?.province?.provinceId"
                                          [zoneId]="param?.zone?.id"
                                          [year]="param.year" id="hmainop"
                                          name="hmainop">
          </transfer-hmain-op-autocomplete>
        </div>
      </div>

      <div class="row mb-3">
        <div class="offset-xl-2 col-auto">
          <button type="button" id="btn-search" class="btn btn-default btn-custom" (click)="search()">
            <img src="assets/icons/search.png" alt="" class="me-2" height="20">
            ค้นหา
          </button>
          <button type="button" id="btn-clear" class="btn btn-default btn-custom ms-2" (click)="clearForm()">
            <i class="bi bi-eraser-fill text-muted fw-bolder me-2"></i>
            เคลียร์ข้อมูล
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="table-responsive">

      <table class="table table-sm table-hover caption-top border">
        <caption class="pt-0">
          <a>ผลการค้นหา</a>
        </caption>
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center">
          <th class="border-1">#</th>
          <th class="border-1">ปีงบประมาณ</th>
          <th class="border-1">หน่วยบริการแม่ข่าย (HMAIN_OP)</th>
          <th class="border-1">จังหวัดที่ตั้ง</th>
          <th class="border-1">จัดการข้อมูล</th>
        </tr>
        </thead>
        <tbody class="table-group-divider border">

        <tr *ngIf="!pageData?.content?.length">
          <td class="text-muted text-center" colspan="5">
            <i class="bi bi-inbox"></i><br>
            ไม่พบข้อมูล
          </td>
        </tr>
        <tr *ngFor="let item of pageData?.content; let i= index">
          <td class="text-center">{{ (pageData.size * pageData.number) + (i + 1) }}</td>
          <td class="text-center">{{item.year}}</td>
          <td>{{ item.hmainOp.hcode }} : {{ item.hmainOp.displayHname }}</td>
          <td>{{ item.hmainOp.province.provinceName }}</td>
          <td class="text-center">
            <button type="button" class="btn btn-default2" (click)="edit(item)">
              <i class="bi bi-pencil-fill"></i>
              แก้ไข
            </button>
            <button type="button" class="btn btn-default2 ms-2" (click)="delete(item)">
              <i class="bi bi-trash text-danger"></i>
              ลบ
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-xl-6">
    <div class="text-muted text-sm-start">
      แสดง {{ (pageData?.content?.length ? (pageData?.number * pageData?.size) + 1 : 0) | number }}
      ถึง {{ ((pageData?.number) * pageData?.size + pageData?.content?.length) | number }}
      จากทั้งหมด {{ pageData?.totalElements | number }} รายการ
    </div>
  </div>
  <div class="col-xl-6">
    <ngb-pagination class="float-end"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    [page]="pageData?.number + 1"
                    (pageChange)="search($event)"
                    [pageSize]="pageData?.size"
                    [collectionSize]="pageData?.totalElements"></ngb-pagination>
  </div>
</div>
