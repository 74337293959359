<div class="modal-header">
  <h6 class="modal-title">รพ.สต. ถ่ายโอน (HSUB)</h6>
  <button type="button" class="btn-close" aria-label="Close"
          (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body overflow-hidden">

  <div class="row mb-3">
    <label class="col-xl-4 col-form-label text-end">ปีงบประมาณ</label>
    <div class="col-xl-6">
      <input type="text" readonly  value="{{data?.year}}"
             class="form-control-plaintext" id="year-readonly">
    </div>
  </div>

  <div class="row mb-3">
    <label class="col-xl-4 col-form-label text-end">จังหวัด</label>
    <div class="col-xl-6">
      <input type="text" readonly  value="{{data?.hmainOp?.province?.provinceName}}"
             class="form-control-plaintext" id="provinceName-readonly">
    </div>
  </div>

  <div class="row mb-3">
    <label class="col-xl-4 col-form-label text-end">หน่วยบริการแม่ข่าย (HMAIN_OP)</label>
    <div class="col-xl-6">
      <input type="text" readonly value="{{ data?.hmainOp?.hcode }} : {{ data?.hmainOp?.displayHname }}"
             class="form-control-plaintext" id="hmainop-readonly">
    </div>
  </div>

  <div class="row mb-3">
    <div class="table-responsive mt-3">
      <table class="table table-sm caption-top border">
        <thead class="color-black font-weight-light">
        <tr class="table-light text-center" style="background-color: #eeeeee;">
          <th scope="col" class="w-100px">ลำดับ</th>
          <th scope="col">รหัส/ชื่อ</th>
          <th scope="col">ตำบล</th>
          <th scope="col">อำเภอ</th>
          <th scope="col">จังหวัด</th>
          <th scope="col" *ngIf="crudMode === 'UPDATE'">จัดการข้อมูล</th>
        </tr>
        </thead>
        <tbody class="table-group-divider">
        <tr *ngIf="!data?.hsubs?.length">
          <td class="text-muted text-center" colspan="6">
            <i class="bi bi-inbox"></i><br>
            ไม่พบข้อมูล
          </td>
        </tr>
        <tr *ngFor="let item of data.hsubs; let i= index">
          <td class="text-center">{{ (i + 1) }}</td>
          <td class="text-start">{{ item.hcode }} : {{ item.displayHname }}</td>
          <td class="text-start">{{ item.catm.subDistrictName }}</td>
          <td class="text-start">{{ item.catm.districtName }}</td>
          <td class="text-start">{{ item.catm.provinceName }}</td>
          <td class="text-center" *ngIf="crudMode === 'UPDATE'">
            <button type="button" id="delete-{{i}}" class="btn btn-default2 ms-2" (click)="delete(item)">
              <i class="bi bi-trash text-danger"></i>
              ลบ
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-12 text-center">
      <button type="button" (click)="activeModal.dismiss()" class="btn btn-default2 btn-custom">
        ปิด
      </button>
    </div>
  </div>
</div>
