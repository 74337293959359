<form (ngSubmit)="save()">
  <div class="card border mb-3">
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-lg-3 text-xl-end my-auto" style="vertical-align: middle">
          เลือกเครือข่ายรพ.สต.ถ่ายโอน
        </div>
        <div class="col">
          <div class="card-body border">
            <div class="row mb-3">
              <label class="col-xl-3 col-form-label text-xl-end ui-require">ปีงบประมาณ</label>
              <div class="col-xl-6">
                <select class="form-select col-lg-5" id="year" name="year" [(ngModel)]="request.year"
                        (ngModelChange)="select($event, this.request.province)">
                  <option selected hidden [ngValue]="undefined">กรุณาเลือก</option>
                  <option *ngFor="let item of years" [ngValue]="item"> {{ item }}</option>
                </select>
                <gt-error field="year" forId="year"></gt-error>
              </div>
              <gt-error forId="year" field="year"></gt-error>
            </div>
            <div class="row mb-3">
              <label class="col-xl-3 col-form-label text-xl-end ui-require">จังหวัด</label>
              <div class="col-xl-6">
                <province-autocomplete id="province" name="province" [restrictZoneId]="zoneId"
                                       (ngModelChange)="select(request.year, $event)"
                                       [(ngModel)]="request.province"></province-autocomplete>
                <gt-error field="province" forId="province"></gt-error>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-xl-3 col-form-label text-xl-end">หน่วยบริการแม่ข่าย (HMAIN_OP)</label>
              <div class="col-xl-9">
                <div class="row mb-3">
                  <ng-container *ngFor="let h of request?.hmainOps; let i = index">
                    <div class="col-xl-6 col-md-6 col-sm-12 mb-2">
                      <a (click)="viewHsub(request.year, h)" ngbTooltip="ดูรพ.สต.ถ่ายโอน" class="btn btn-default2 me-2"
                         id="mainOp-{{i}}" name="mainOp-{{i}}">
                        <i class="bi bi-search text-primary"></i>
                      </a>
                      <span>{{ h.hcode }} : {{ h.displayHname }}</span>
                    </div>
                  </ng-container>
                </div>
                <div class="row mb-3">
                  <div class="col-12">
                    <span class="badge bg-primary-subtle border border-light-subtle text-light-emphasis">*** ระบบจะแสดงเฉพาะรหัสที่ยังไม่มีการบันทึกข้อมูลเท่านั้น</span>
                  </div>
                </div>
                <gt-error field="hmainOps"></gt-error>
              </div>
            </div>
            <div class="row mb">
              <div class="col-12">
                <span class="text-danger fw-bold">หมายเหตุ : กรณีพบ รพ.สต. ถ่ายโอนไม่ครบถ้วนให้ติดต่อฝ่ายบริหารงานทะเบียนเพื่อทำการตรวจสอบ/เพิ่มเติม ก่อนการบันทึกข้อมูล</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col">
          <div class="table-responsive">
            <table class="table table-sm table-hover caption-top border">
              <caption class="pt-0">
                <div class="text-end">
                  <button type="button" class="btn btn-default btn-custom float-right" id="add-service"
                          [disabled]="!request?.hmainOps?.length"
                          (click)="addService()"><i
                    class="bi bi-arrow-down-circle-fill text-success fw-bolder me-2"></i>เพิ่มกิจกรรม
                  </button>
                </div>
              </caption>
              <thead class="color-black font-weight-light">
              <tr class="table-light text-center">
                <th class="border-1" rowspan="2">ลำดับ</th>
                <th class="border-1" rowspan="2">ปีงบประมาณ</th>
                <th class="border-1" rowspan="2">กิจกรรม</th>
                <th class="border-1" rowspan="2">วันที่เริ่มต้นมีผลบังคับใช้</th>
                <th class="border-1" rowspan="2">วันที่สิ้นสุดมีผลบังคับใช้</th>
                <th class="border-1" rowspan="2">รูปแบบการจ่าย</th>
                <th class="border-1" rowspan="2">กำหนดเงื่อนไขการจ่าย(สำหรับฝั่ง รพ.สต.)</th>
                <th class="border-1" rowspan="2">ไฟล์แนบ</th>
                <th class="border-1 w-120px" rowspan="2">จัดการ</th>
              </tr>
              </thead>
              <tbody class="table-group-divider border">
              <tr *ngIf="!request?.items?.length">
                <td class="text-muted text-center" colspan="9">
                  <i class="bi bi-inbox"></i><br>
                  ไม่พบข้อมูลหน่วยบริการ
                </td>
              </tr>
              </tbody>
              <tbody class="table-group-divider border">
              <tr *ngFor="let item of request.items; let i = index; let f = first">
                <td class="text-center maxw-50px">{{ i + 1 }}</td>
                <td *ngIf="f" [rowSpan]="request.items.length" class="text-center">{{ request.year }}
                </td>
                <td class="text-start">
                  <span>{{ item?.serviceType?.type }} : {{ item?.serviceType?.name }}</span>
                  <br/>
                  <ng-container *ngIf="f">
                    <button (click)="copyToOther()" id="{{i}}-copy-service" type="button"
                            class="btn btn-default2 bg-white me-2 mb-2"><i class="bi bi-copy text-success me-1"></i>
                      copy ค่า
                    </button>
                    <button (click)="reset()" id="{{i}}-copy-service" type="button"
                            class="btn btn-default2 bg-white me-2 mb-2"><i class="bi bi-copy text-success me-1"></i>
                      เคลียร์
                    </button>
                  </ng-container>
                </td>
                <td class="text-start align-text-top">
                  <gt-ng-datepicker id="dateStart-{{i}}" [(ngModel)]="item.startDate"
                                    name="dateStart-{{i}}"></gt-ng-datepicker>
                  <gt-error forId="dateStart-{{i}}" field="items[{{i}}].startDate"></gt-error>
                </td>
                <td class="text-start align-text-top">
                  <gt-ng-datepicker [minDate]="getMinFromStart(item.startDate, dateParser)" id="endDate-{{i}}"
                                    [(ngModel)]="item.endDate"
                                    name="endDate-{{i}}"></gt-ng-datepicker>
                  <gt-error forId="endDate-{{i}}" field="items[{{i}}].endDate"></gt-error>
                </td>
                <td class="text-start align-text-top">
                  <select class="form-select" id="payment-type-{{i}}" name="payment-type-{{i}}"
                          [(ngModel)]="item.paymentType">
                    <option selected hidden [ngValue]="undefined">เลือก</option>
                    <option *ngFor="let item of paidTypes" [ngValue]="item"> {{ item }}</option>
                  </select>
                  <gt-error forId="payment-type-{{i}}" field="items[{{i}}].paymentType"></gt-error>
                </td>
                <td nowrap>
                  @if (item.paymentType === 2) {
                    <div class="col">
                      <div class="row mb-2">
                        <div class=" col">
                          <div class="row">
                            <div class="col-md-3">
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="radio-{{i}}" id="radio1-{{i}}"
                                       [(ngModel)]="item['paymentTypeMock']" [value]="true"
                                       (change)="selectPaymentType(item)">
                                <label class="form-check-label" for="radio1-{{i}}">ตัวเงิน</label>
                              </div>
                            </div>
                            <div class="col-md-8">
                              <div class="input-group">
                                <input type="text" id="ิbath-{{i}}" name="bath-{{i}}"
                                       [disabled]="item['paymentTypeMock'] !== true"
                                       digitOnly
                                       [decimal]="true"
                                       pattern="^\d+(\.\d{1,2})?$"
                                       [(ngModel)]="item.paymentAmount" class="form-control">
                                <div class="input-group-append">
                                  <span style="width: 50px;" class="my-auto input-group-text">บาท</span>
                                </div>
                                <gt-error field="items[{{i}}].paymentAmount"></gt-error>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="row">
                            <div class="col-md-3">
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="radio-{{i}}"
                                       [(ngModel)]="item['paymentTypeMock']" id="radio2-{{i}}" [value]="false"
                                       (change)="selectPaymentType(item)">
                                <label class="form-check-label" for="radio2-{{i}}">ร้อยละ</label>
                              </div>
                            </div>
                            <div class="col-md-8">
                              <div class="input-group">
                                <input type="text" id="percent-{{i}}" name="percent-{{i}}"
                                       [disabled]="item['paymentTypeMock'] !== false"
                                       digitOnly
                                       [decimal]="true"
                                       pattern="^\d+(\.\d{1,2})?$"
                                       [(ngModel)]="item.paymentPercent" class="form-control">
                                <div class="input-group-append">
                                  <span style="width: 50px;" class="my-auto input-group-text">%</span>
                                </div>
                              </div>
                              <gt-error field="items[{{i}}].paymentPercent"></gt-error>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </td>
                <td nowrap>
                  <app-file-upload [size]="'sm-inline'" id="file-{{i}}"
                                   name="file-{{i}}"
                                   [(ngModel)]="item.file"></app-file-upload>
                </td>
                <td class="text-center">
                  @if (!f) {
                    <button type="button" id="remove-item-{{i}}" class="btn btn-default2 btn-sm me-2"
                            (click)="removeItem(item)">
                      <i class="bi bi-trash text-danger"></i> ลบ
                    </button>
                  }
                </td>
              </tr>
              </tbody>
            </table>
            <gt-error field="items"></gt-error>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col text-end">
          <fieldset [disabled]="!request?.hmainOps?.length">
            <button type="submit" (click)="request.status = 'PREPARING'"
                    class="btn btn-outline-primary btn-custom me-3">
              <i class="bi bi-floppy2-fill me-2"></i>
              อยู่ระหว่างการบันทึกข้อมูล
            </button>
            <button type="submit" (click)="request.status = 'COMPLETE'" class="btn btn-success btn-custom">
              <i class="bi bi-floppy2-fill me-2"></i>
              บันทึกครบถ้วนแล้ว
            </button>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</form>
