<form #form="ngForm" class="form-validate" (ngSubmit)="submit()">
  <div class="modal-header">
    <h6 class="modal-title">เลือกบริการ (SERVICE_TYPE)</h6>
    <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body overflow-hidden">
    <div class="scrollable-box">
      <div class="overflow-box">
        <oss-service-type-hsub-autocomplete id="service-type" name="service-type"
                                            (ossServiceType)="selectService($event)"
                                            [exists]="exists"></oss-service-type-hsub-autocomplete>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12 text-center">
        <button type="submit" class="btn btn-primary btn-custom me-2" [disabled]="!services.length">
          ตกลง
        </button>
        <button type="button" (click)="activeModal.dismiss()" class="btn btn-default2 btn-custom">
          ยกเลิก
        </button>
      </div>
    </div>
  </div>
</form>
