import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OidcSecurityService} from "angular-auth-oidc-client";
import {filter, take} from "rxjs/operators";
import {SharedCommonModule} from "../../shared/commons/shared-common.module";
import {AppSecurityModule} from "../../security/app-security.module";

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [CommonModule, SharedCommonModule, AppSecurityModule],
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  constructor(private oidc: OidcSecurityService) {
  }

  ngOnInit(): void {
    this.oidc
      .userData$
      .pipe(
        filter(value => !!value),
        take(1)
      ).subscribe(value => {
    });
  }
}
