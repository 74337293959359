import {Directive, inject, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {OidcSecurityService} from "angular-auth-oidc-client";
import {environment} from "../../environments/environment";
import {filter} from "rxjs/operators";
import {Authority} from "../../generated-model/model";
import {UserDataService} from "../shared/services/user-data.service";

@Directive({
  selector: '[hasAnyAuthority]'
})
export class HasAnyAuthorityDirective {
  private alreadyAddView = false;
  private userDataService = inject(UserDataService);

  constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>, public oidc: OidcSecurityService) {
  }

  @Input({required: true})
  set hasAnyAuthority(checkAuthorities: Authority[]) {
    this.oidc.userData$
      .subscribe(u => {
        let userAuthorities: Authority[] = u.userData?.resource_access?.[environment.clientId]?.roles || [];
        let realmRole: Authority[] = u.userData?.realm_access?.roles || [];
        userAuthorities = [...userAuthorities, ...realmRole];
        this.userDataService.getCurrent()
          .subscribe(userData => {
            userAuthorities = [...userAuthorities, ...(userData?.authorities?.map(a => a.authority as Authority) || [])];
            if (this.hasAny(checkAuthorities, userAuthorities)) {
              if (!this.alreadyAddView) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.alreadyAddView = true;
              }
            } else {
              this.viewContainer.clear();
              this.alreadyAddView = false;
            }
          });
      })
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.alreadyAddView = true;
  }

  private hasAny(authorityList: Authority[], authorities: string[] = []): boolean {
    if (authorities) {
      return authorities.some(e => authorityList.some(ex => e == ex));
    } else {
      return false;
    }
  }

}
