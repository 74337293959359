<form (ngSubmit)="save()">
    <div class="card border mb-3">
      <div class="card-body ">
        <div class="row mb-3">
          <div class="col-lg-3 text-xl-end my-auto" style="vertical-align: middle">
            เลือกเครือข่ายรพ.สต.ถ่ายโอน
          </div>
          <div class="col">
            <div class="card-body border">
              <div class="row mb-3">
                <label class="col-xl-3 col-form-label text-xl-end ui-require">ปีงบประมาณ</label>
                <div class="col-xl-6">
                  <select class="form-select col-lg-5" id="year" name="year" [(ngModel)]="year" [disabled]="crudMode === 'READ'">
                    <option selected hidden [ngValue]="undefined">กรุณาเลือก</option>
                    <option *ngFor="let item of years" [ngValue]="item"> {{ item }}</option>
                  </select>
                  <gt-error field="year" forId="year"></gt-error>
                </div>
              </div>

              <div class="row mb-3">
                <label for="hmainop" class="col-xl-3 col-form-label text-xl-end ui-require">หน่วยบริการแม่ข่าย
                  (HMAIN_OP)</label>
                <div class="col-xl-6">
                  <input type="text" readonly *ngIf="!year" value="กรุณาระบุปีงบประมาณ" class="form-control-plaintext"
                         id="hmainop-readonly">
                  <transfer-hmain-op-autocomplete [(ngModel)]="hYearDto" [disabled]="crudMode === 'READ'"
                                                  [crudMode]="crudMode"
                                                  [hmainOpExsist]="hmainOpExsist"
                                                  *ngIf="year" [year]="year" id="hmainop" name="hmainop">
                  </transfer-hmain-op-autocomplete>
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-xl-3 col-form-label text-xl-end">รพ.สต. ถ่ายโอน (HSUB)</label>
                <div class="col-xl-6">

                  @if (crudMode !== 'READ') {
                    <button type="button" class="btn btn-default2 mb-2" (click)="addHsub()"
                            *ngIf="hYearDto?.hmainOp">
                      <i class="bi bi-plus text-success"></i>เพิ่ม HSUB
                    </button>
                  }

                  @for (hsub of hYearDto?.hsubs; track $index) {
                    <div class="mb-2">
                      <a *ngIf="crudMode !== 'READ'" class="btn btn-default2 me-2" name="{{$index}}-hsub"
                         id="{{$index}}-hsub" (click)="removeHsub(hsub)">
                        <i class="bi bi-trash text-danger"></i>
                      </a>
                      <span>{{ hsub.hcode }} : {{ hsub.displayHname }}</span>
                    </div>
                  }
                  <gt-error field="hsubs"></gt-error>
                </div>
              </div>
            </div>
          </div>
        </div>

        @if (crudMode !== 'READ') {
          <div class="row mb-3">
            <div class="offset-xl-5 col">
              <button type="button" class="btn btn-default btn-custom" [disabled]="!hYearDto?.hsubs" (click)="add()">
                <i class="bi bi-arrow-down-circle-fill text-success fw-bolder me-2"></i>
                เพิ่ม
              </button>
              <button type="button" class="btn btn-default btn-custom ms-2" (click)="clear()">
                <i class="bi bi-eraser-fill text-muted fw-bolder me-2"></i>
                เคลียร์
              </button>
            </div>
          </div>
        }
        <div class="row mb-3">
          <div class="offset-xl-2 col">
            <span class="badge bg-warning-subtle border border-light-subtle text-light-emphasis">{{ADD_ITEM_WARNING}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="table-responsive">
              <table class="table table-sm table-hover caption-top border">
                <thead class="color-black font-weight-light">
                <tr class="table-light text-center">
                  <th class="border-1" rowspan="2">ลำดับ</th>
                  <th class="border-1" rowspan="2">ปีงบประมาณ</th>
                  <th class="border-1" colspan="1">หน่วยบริการปฐมภูมิ (HSUB)</th>
                  <th class="border-1" rowspan="2">กิจกรรม</th>
                  <th class="border-1" rowspan="2">วันที่เริ่มต้นมีผลบังคับใช้</th>
                  <th class="border-1" rowspan="2">วันที่สิ้นสุดมีผลบังคับใช้</th>
                  <th class="border-1" rowspan="2">รูปแบบการจ่าย</th>
                  <th class="border-1" rowspan="2">กำหนดเงื่อนไขการจ่าย(สำหรับฝั่ง รพ.สต.)</th>
                  <th class="border-1" rowspan="2">ไฟล์แนบ</th>
                  <th class="border-1 w-120px" rowspan="2">จัดการ</th>
                </tr>
                </thead>
                <tbody class="table-group-divider border">
                <tr *ngIf="!dto?.transferServices?.length">
                  <td class="text-muted text-center" colspan="10">
                    <i class="bi bi-inbox"></i><br>
                    ไม่พบข้อมูลหน่วยบริการ
                  </td>
                </tr>
                </tbody>
                <tbody class="table-group-divider border">
                <ng-container *ngFor="let item of dto.transferServices; let i= index; let f = first">
                  <tr *ngFor="let subItems of item.items; let j = index; let f2 = first">
                    <td *ngIf="f2" [rowSpan]="item.items.length" class="text-center maxw-50px">{{ i + 1 }}</td>
                    <td *ngIf="f2" [rowSpan]="item.items.length" class="text-center">{{ dto?.transferYear.year }}</td>
                    <td *ngIf="f2" [rowSpan]="item.items.length" class="text-start">
                        {{ item?.hsub?.hcode }} / {{ item?.hsub?.displayHname }}
                      @if (crudMode !== 'READ') {
                        <br/>
                        <button type="button" class="btn btn-default2 mb-2 me-2" id="{{i}}-add-service" (click)="addHsubService(item)"><i class="bi bi-plus text-success me-1"></i>เพิ่มกิจกรรม</button>
                        <button *ngIf="f" (click)="copyToOther()" id="{{i}}-copy-service" type="button" class="btn btn-default2 bg-white me-2 mb-2"><i class="bi bi-copy text-success me-1"></i> copy ค่า</button>
                      }
                      <a role="button" class="btn btn-outline-primary mb-2 me-2" id="{{i}}-collapse" (click)="itemToggle(item)"><i [ngClass]="{'bi-arrows-angle-contract' : item['show'], 'bi-arrows-angle-expand' : !item['show']}"  class="bi me-1"></i>{{item['show'] ? 'ย่อ' : 'ขยาย'}}</a>
                    </td>
                    <ng-container *ngIf="item['show']">
                      <td class="text-start">
                        <span *ngIf="subItems?.serviceType">{{subItems?.serviceType?.type}} : {{subItems?.serviceType?.name}}</span>
                        <gt-error field="transferServices[{{i}}].items[{{j}}].serviceType"></gt-error>
                      </td>
                      <td class="text-start align-text-top">
                        <gt-ng-datepicker [readonly]="crudMode === 'READ'" id="dateStart-{{i}}-{{j}}" [(ngModel)]="subItems.startDate" name="dateStart-{{i}}-{{j}}"></gt-ng-datepicker>
                        <gt-error forId="dateStart-{{i}}-{{j}}" field="transferServices[{{i}}].items[{{j}}].startDate"></gt-error>
                      </td>
                      <td class="text-start align-text-top">
                        <gt-ng-datepicker [minDate]="getMinFromStart(subItems.startDate, dateParser)" [readonly]="crudMode === 'READ'" id="endDate-{{i}}-{{j}}" [(ngModel)]="subItems.endDate" name="endDate-{{i}}-{{j}}"></gt-ng-datepicker>
                        <gt-error forId="endDate-{{i}}-{{j}}" field="transferServices[{{i}}].items[{{j}}].endDate"></gt-error>
                      </td>
                      <td class="text-start align-text-top">
                        <select class="form-select" id="payment-type-{{i}}-{{j}}" name="payment-type-{{i}}-{{j}}" [(ngModel)]="subItems.paymentType" [disabled]="crudMode === 'READ'">
                          <option selected hidden [ngValue]="undefined">เลือก</option>
                          <option *ngFor="let item of paidTypes" [ngValue]="item"> {{ item }}</option>
                        </select>
                        <gt-error forId="payment-type-{{i}}-{{j}}" field="transferServices[{{i}}].items[{{j}}].paymentType"></gt-error>
                      </td>
                      <td nowrap>
                        @if (subItems.paymentType === 2) {
                          <fieldset [disabled]="crudMode === 'READ'">
                            <div class="col">
                              <div class="row mb-2">
                                <div class=" col">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="radio-{{i}}-{{j}}"
                                               id="radio1-{{i}}-{{j}}"
                                               [(ngModel)]="subItems['paymentTypeMock']" [value]="true"
                                               (change)="selectPaymentType(subItems)">
                                        <label class="form-check-label" for="radio1-{{i}}-{{j}}">ตัวเงิน</label>
                                      </div>
                                    </div>
                                    <div class="col-md-8">
                                      <div class="input-group">
                                        <input type="text" id="ิbath-{{i}}-{{j}}" name="bath-{{i}}-{{j}}"
                                               [disabled]="subItems['paymentTypeMock'] !== true"
                                               digitOnly
                                               [decimal]="true"
                                               pattern="^\d+(\.\d{1,2})?$"
                                               [(ngModel)]="subItems.paymentAmount" class="form-control">
                                        <div class="input-group-append">
                                          <span style="width: 50px;" class="my-auto input-group-text">บาท</span>
                                        </div>
                                        <gt-error field="transferServices[{{i}}].items[{{j}}].paymentAmount"></gt-error>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="radio-{{i}}-{{j}}"
                                               [(ngModel)]="subItems['paymentTypeMock']" id="radio2-{{i}}-{{j}}"
                                               [value]="false"
                                               (change)="selectPaymentType(subItems)">
                                        <label class="form-check-label" for="radio2-{{i}}-{{j}}">ร้อยละ</label>
                                      </div>
                                    </div>
                                    <div class="col-md-8">
                                      <div class="input-group">
                                        <input type="text" id="percent-{{i}}-{{j}}" name="percent-{{i}}-{{j}}"
                                               [disabled]="subItems['paymentTypeMock'] !== false"
                                               digitOnly
                                               [decimal]="true"
                                               pattern="^\d+(\.\d{1,2})?$"
                                               [(ngModel)]="subItems.paymentPercent" class="form-control">
                                        <div class="input-group-append">
                                          <span style="width: 50px;" class="my-auto input-group-text">%</span>
                                        </div>
                                      </div>
                                      <gt-error field="transferServices[{{i}}].items[{{j}}].paymentPercent"></gt-error>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        }
                      </td>
                      <td nowrap>
                        <app-file-upload [disabled]="crudMode === 'READ'" [size]="'sm-inline'" id="file-{{i}}-{{j}}"
                                         name="file-{{i}}-{{j}}"
                                         [(ngModel)]="subItems.file"></app-file-upload>
                      </td>
                      <td class="text-center">
                        @if (crudMode !== 'READ' && subItems?.serviceType) {
                          <button type="button" id="remove-item-{{i}}-{{j}}" class="btn btn-default2 btn-sm me-2"
                                  (click)="removeItem(item,subItems)">
                            <i class="bi bi-trash text-danger"></i> ลบ
                          </button>
                        }
                      </td>
                    </ng-container>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col text-end">
            @if (crudMode !== 'READ') {
              <button type="submit" (click)="dto.status = 'PREPARING'" class="btn btn-outline-primary btn-custom me-3">
                <i class="bi bi-floppy2-fill me-2"></i>
                อยู่ระหว่างการบันทึกข้อมูล
              </button>
              <button type="submit" (click)="dto.status = 'COMPLETE'" class="btn btn-success btn-custom">
                <i class="bi bi-floppy2-fill me-2"></i>
                บันทึกครบถ้วนแล้ว
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  </form>
