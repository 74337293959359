import {Component, inject, OnInit} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {
  DistrictAutocompleteComponent
} from "../../../../../shared/components/district-autocomplete/district-autocomplete.component";
import {FormsModule} from "@angular/forms";
import {NgbActiveModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {
  ProvinceAutocompleteComponent
} from "../../../../../shared/components/province-autocomplete/province-autocomplete.component";
import {SharedModule} from "../../../../../shared/shared.module";
import {
  SubdistrictAutocompleteComponent
} from "../../../../../shared/components/subdistrict-autocomplete/subdistrict-autocomplete.component";
import {
  OssServiceTypeHsubAutocompleteComponent
} from "../../../../../shared/components/oss-service-type-hsub-autocomplete/oss-service-type-hsub-autocomplete.component";
import {OssServiceTypeHsubDto} from "../../../../../../generated-model/model";
import {CRUD} from "../../../../../app.constants";

@Component({
  selector: 'app-add-service-modal',
  standalone: true,
  imports: [
    DecimalPipe,
    DistrictAutocompleteComponent,
    FormsModule,
    NgForOf,
    NgIf,
    NgbPagination,
    ProvinceAutocompleteComponent,
    SharedModule,
    SubdistrictAutocompleteComponent,
    OssServiceTypeHsubAutocompleteComponent
  ],
  templateUrl: './add-service-modal.component.html',
  styleUrl: './add-service-modal.component.scss'
})
export class AddServiceModalComponent implements OnInit {
  public activeModal = inject(NgbActiveModal);
  crudMode: CRUD = 'CREATE'
  exists: OssServiceTypeHsubDto[] = [];
  services: OssServiceTypeHsubDto[] = [];


  submit() {
    this.activeModal.close(this.services);
  }

  ngOnInit(): void {
  }

  selectService($event: OssServiceTypeHsubDto[]) {
    this.services = $event;
  }
}
