<ng-select [id]="id"
           [name]="name"
           [items]="hospitalTransferYearDtos"
           placeholder="พิมพ์รหัส หรือชื่อเพื่อค้นหา"
           [disabled]="disabled"
           [clearable]="true"
           [compareWith]="compareWithCode"
           [editableSearchTerm]="true"
           [typeahead]="term$"
           [loading]="typeaheadLoading"
           (ngModelChange)="valueChange($event)"
           (open)="onOpen()"
           [required]="required"
           #ngSelectComponent
           #val="ngModel"
           [(ngModel)]="value">
  <ng-template ng-label-tmp let-item="item">
    <i *ngIf="item?.completeProcessData" class="bi bi-check-circle-fill text-success fw-bolder me-1"></i>
    {{ item?.hmainOp?.hcode }} {{item?.hmainOp?.hcode? ':' : '' }} {{ item?.hmainOp?.displayHname }} {{item?.hmainOp?.catm?.fullCatm }}
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
    <i *ngIf="item?.completeProcessData" class="bi bi-check-circle-fill text-success fw-bolder me-1"></i>
    {{ item?.hmainOp?.hcode }} : {{ item?.hmainOp?.displayHname }} {{item?.hmainOp?.catm?.fullCatm }}
  </ng-template>
</ng-select>
