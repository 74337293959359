import {
  AfterViewInit,
  Component, EventEmitter,
  forwardRef,
  inject,
  Input,
  OnInit, Output,
  ViewChild
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgForm
} from "@angular/forms";
import {debounceTime, distinctUntilChanged, Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {NgSelectModule} from "@ng-select/ng-select";
import {map, switchMap} from "rxjs/operators";
import {IgnoreNullHttpParams} from "../../../../../shared/commons/Ignore-null-http-params";
import {environment} from "../../../../../../environments/environment";
import {AsyncPipe, NgIf} from "@angular/common";
import {CRUD, PageRequest} from "../../../../../app.constants";
import {HospitalTransferYearDto, HospitalTransferYearParam, Page} from "../../../../../../generated-model/model";
import {HospitalTransferYearService} from "../../../services/hospital-transfer-year.service";

@Component({
  selector: 'transfer-hmain-op-autocomplete',
  templateUrl: './transfer-hmain-op-autocomplete.component.html',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TransferHmainOpAutocompleteComponent),
      multi: true
    }
  ],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm
  }],
  imports: [
    FormsModule,
    NgSelectModule,
    AsyncPipe,
    NgIf,
  ],
})
export class TransferHmainOpAutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  private _hospitalTransferYearService = inject(HospitalTransferYearService);
  hospitalTransferYearDtos: HospitalTransferYearDto[] = [];
  term$ = new Subject<string>();
  typeaheadLoading = false;
  _value: HospitalTransferYearDto = undefined;
  @Input('id') id: string = 'hospital';
  @Input('name') name: string = 'hospital';
  @Input() disabled: boolean = false;
  private http = inject(HttpClient);
  @Input() required: boolean = false;
  @Input({required: true}) year: number
  @Output() hTransferYear: EventEmitter<HospitalTransferYearDto> = new EventEmitter();
  @Input() crudMode: CRUD = 'READ'
  @Input() zoneId: string
  @Input() provinceId: string
  @Input() hmainOpExsist: HospitalTransferYearDto = {}

  onChange: any = () => {
  }
  onTouched = () => {
  };
  private onChangeCallback: (_: HospitalTransferYearDto) => void = () => {
  };
  compareWithCode = (o1: HospitalTransferYearDto, o2: HospitalTransferYearDto): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1?.id == o2?.id))
  };
  @ViewChild(NgControl) innerNgControl: NgControl;

  constructor() {
  }

  ngAfterViewInit() {
    if (!this.year) {
      throw new Error('year is required')
    }
    this.filterExsistHmainOp()
  }

  ngOnInit(): void {
    this.term$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(term => this.fetchData(term))
      )
      .subscribe(data => {
        this.hospitalTransferYearDtos = data;
        this.updateDisabledState();
        this.typeaheadLoading = false; // hide the spinner
      });
  }

  updateDisabledState(): void {
    // disable hmainOp that already complete process
    if (this.crudMode === 'CREATE' || this.crudMode === 'UPDATE' ) {
      this.hospitalTransferYearDtos.forEach(h => {
        h['disabled'] = h.completeProcessData
        // update mode can select only hmainOp that already exist
        if (this.crudMode === 'UPDATE') {
          if (h.hmainOp?.hcode === this.hmainOpExsist?.hmainOp?.hcode) {
            h['disabled'] = false
          }
        }
      });
    }
  }

  fetchData(term?: string): Observable<HospitalTransferYearDto[]> {
    const pageRequest: PageRequest = {size: 10}
    let searchParam: HospitalTransferYearParam = {
      term: term,
      zoneId: this.zoneId,
      provinceId : this.provinceId
    }
    return this._hospitalTransferYearService.search(this.year, searchParam, pageRequest).pipe(map(e => e.content));
  }

  writeValue(value: HospitalTransferYearDto) {
    if (value && value !== this._value) {
      this._value = value;
    }
  }

  get value(): HospitalTransferYearDto {
    return this._value;
  }

  set value(value: HospitalTransferYearDto) {
    if (value !== this._value) {
      this._value = value;
      this.onChangeCallback(value);

    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  valueChange(value: HospitalTransferYearDto) {
    this.onChange(value);
    this.onTouched();
    this.hTransferYear.emit(value);
  }

  onOpen() {
    this.filterExsistHmainOp()
  }

  filterExsistHmainOp() {
    this.term$.next(this.hmainOpExsist?.hmainOp?.hcode?.substring(0,4) || '');
  }
}
