<ng-select [id]="id"
           [name]="name"
           [items]="districts"
           placeholder="กรุณาเลือก"
           class="novalidate"
           bindLabel="name"
           [disabled]="disabled"
           [searchable]="true"
           [clearable]="true"
           [compareWith]="compareWithCode"
           [editableSearchTerm]="true"
           [typeahead]="term$"
           [loading]="typeaheadLoading"
           [required]="required"
           (ngModelChange)="valueChange($event)"
           #ngSelectComponent
           #val="ngModel"
           [(ngModel)]="value">
</ng-select>
@if(val.touched) {
<custom-error *ngIf="val.invalid && val.errors['required']" error="กรุณาเลือกจังหวัด"></custom-error>
}
