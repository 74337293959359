import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {saveAs} from "file-saver";
import {
  HospitalDto,
  HospitalTransferYearDto, HospitalTransferYearImportDto, HospitalTransferYearParam,
  ImportFile, Page
} from "../../../../generated-model/model";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {PageRequest} from "../../../app.constants";

@Injectable({
  providedIn: 'root'
})
export class HospitalTransferYearService {
  private http = inject(HttpClient);

  constructor() {
  }

  years$(): Observable<number[]> {
    return this.http.get<number[]>(`${environment.serverUrl}/api/hospital-transfer-year/years`)
  }

  downloadFileTemplate() {
    this.http.get(`${environment.serverUrl}/api/hospital-transfer-year/template/download`, {responseType: "blob"})
      .subscribe(data => {
        saveAs(new Blob([data]), 'template_hospital_transfer_year.xlsx');
      });
  }

  excelMapper(importFile: ImportFile) {
    let params = IgnoreNullHttpParams.fromObject(importFile);
    return this.http.get<HospitalTransferYearImportDto[]>(`${environment.serverUrl}/api/hospital-transfer-year/template/view`, {params: params.toHttpParam()})
  }

  saveImport(importFile: ImportFile) {
    return this.http.post<HospitalTransferYearDto>(`${environment.serverUrl}/api/hospital-transfer-year/template/save`, importFile)
  }

  availableHmainOp(year: number, provinceId: string): Observable<HospitalDto[]> {
    let params = IgnoreNullHttpParams.fromObject({year: year, provinceId: provinceId});
    return this.http.get<HospitalDto[]>(`${environment.serverUrl}/api/hospital-transfer-year/available-hmainOp`, {params: params.toHttpParam()})
  }

  search(year: number, searchParam: HospitalTransferYearParam, pageRequest?: PageRequest): Observable<Page<HospitalTransferYearDto>> {
    const param = IgnoreNullHttpParams.fromObject({...searchParam, ...pageRequest}).toHttpParam()
    return this.http.get<Page<HospitalTransferYearDto>>(`${environment.serverUrl}/api/hospital-transfer-year/search/${year}`, {
      params: param
    })
  }

}
