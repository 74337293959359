import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ACCEPT_DATE_FORMAT, ISODATE_FORMAT} from "./date-format";

@Component({
  selector: 'gt-ng-datepicker',
  templateUrl: './gt-ng-datepicker.component.html',
  styleUrls: ['./gt-ng-datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GtNgDatepickerComponent),
      multi: true
    },
    // {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class GtNgDatepickerComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  private _value: string = undefined;
  correctFormat: boolean = true;

  hasError = false;
  @Input()
  hasTime: boolean = false;
  @Input()
  id: string = 'gt-ng-datepicker';
  @Input()
  name: string = 'gt-ng-datepicker';
  @Input()
  minDate: NgbDateStruct;
  @Input()
  maxDate: NgbDateStruct;
  @Input()
  readonly: boolean = false;
  @Input()
  markDisabled = false

  // TO DO FIX CHANGE EVT DOESN"T FIRE
  // change event doesnt fire, so use this for quick fix
  @Output()
  valueOnchange = new EventEmitter();

  @ViewChild("datepicker", {static: true})
  datepicker: ElementRef;

  @Input() size: 'sm' | 'md' = 'md';

  onChangeCallBack: (_: any) => void = () => {
  };
  onTouchCallBack: () => void = () => {
  };

  constructor(private cdr: ChangeDetectorRef,
              private renderer: Renderer2) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

  }

  registerOnChange(fn: any): void {
    this.onChangeCallBack = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchCallBack = fn;
  }

  writeValue(obj: any): void {
    this.value = obj
  }

  error() {
    console.log('this.datepicker = ', this.datepicker)
    this.hasError = true;
    this.renderer.addClass(this.datepicker.nativeElement, 'ng-invalid');
  }

  clearError() {
    this.hasError = false;
    this.renderer.removeClass(this.datepicker.nativeElement, 'ng-invalid');
  }

  widthAuto() {
    // this.elementRef.nativeElement.querySelector(".dropdown-menu").style.width = "auto";
  }

  set value(val: any) {

    this._value = val;
    if (!!this._value && ACCEPT_DATE_FORMAT.test(this._value)) {
      let value: string[] = this._value.split('/');
      this._value = `${Number(value[2])}-${value[1]}-${value[0]}`;
      this.onChangeCallBack(this._value);
    } else if (!!this._value && ISODATE_FORMAT.test(this._value)) {
      let T = this._value.indexOf('T');
      if (T != -1) {
        this.onChangeCallBack(this._value);
      } else {
        this._value = this._value.split('T')[0];
        this.onChangeCallBack(this._value);
        // this.onChangeCallBack(this._value+'T00:00:00');
      }
    } else {
      this._value = undefined;
      this.onChangeCallBack(this._value);
    }
    this.correctFormat = !!this._value;
    this.onTouchCallBack();
    this.cdr.detectChanges();
  }

  get value(): any {
    return this._value;
  }

  private errorWithTimeout() {
    this.error()
    setTimeout(() => this.clearError(), 2000)
  }
}
