<ng-select [id]="id" #ngSelectComponent
           [name]="name"
           [items]="provinces$ | async"
           placeholder="กรุณาเลือก"
           bindLabel="provinceName"
           [disabled]="disabled"
           [clearable]="true"
           [searchable]="true"
           [compareWith]="compareWithCode"
           [editableSearchTerm]="true"

           [loading]="typeaheadLoading"
           [required]="required"
           #val="ngModel"
           [(ngModel)]="value"
           (ngModelChange)="valueChange($event)"
>
</ng-select>
@if(val.touched) {
  <custom-error *ngIf="val.invalid && val.errors['required']" error="กรุณาเลือกจังหวัด"></custom-error>
}
