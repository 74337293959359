import {Component, inject, OnInit} from '@angular/core';
import {
  HospitalDto,
  HospitalTransferDto, HospitalTransferServiceDto, HospitalTransferServiceItemDto,
  HospitalTransferYearDto,
  OssServiceTypeHsubDto
} from "../../../../generated-model/model";
import {HospitalTransferService} from "../services/hospital-transfer.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ADD_ITEM_WARNING, CRUD, getFiscalYear, getFiscalYearDateRange, getMinFromStart} from "../../../app.constants";
import {ConfirmDialogService} from "../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {ToastService} from "../../../shared/commons/toast/toast-service";
import {HospitalTransferYearService} from "../services/hospital-transfer-year.service";
import {TransferHsubModalDialogService} from "./components/transfer-hsub-modal/transfer-hsub-modal-dialog.service";
import {AddServiceModalDialogService} from "./components/add-service-modal/add-service-modal-dialog-service.service";
import {filter} from "rxjs/operators";
import {CustomNgbDateParser} from "../../../shared/commons/gt-ng-datepicker/custom-ngb-date-parser";

export const paidTypes: number[] = [1, 2, 3]

@Component({
  selector: 'app-hospital-transfer-detail',
  templateUrl: './hospital-transfer-detail.component.html',
  styleUrl: './hospital-transfer-detail.component.scss'
})
export class HospitalTransferDetailComponent implements OnInit {
  crudMode: CRUD = 'READ'
  private _hospitalTransferService = inject(HospitalTransferService);
  private _activatedRoute = inject(ActivatedRoute);
  private confirmDialogService = inject(ConfirmDialogService);
  private _hospitalTransferYearService = inject(HospitalTransferYearService);
  private _transferHsubModalDialogService = inject(TransferHsubModalDialogService);
  private addServiceModalDialogService = inject(AddServiceModalDialogService);
  private toastService: ToastService = inject(ToastService);
  public dateParser: CustomNgbDateParser = inject(CustomNgbDateParser);
  paidTypes: number[] = paidTypes
  years: number[] = []
  year: number
  dto: HospitalTransferDto = {}
  hYearDto: HospitalTransferYearDto
  hmainOpExsist: HospitalTransferYearDto = {}

  constructor(private router: Router) {
    this.crudMode = this._activatedRoute.snapshot.data['mode'];
    this.initUpdateAndView()
  }

  initUpdateAndView() {
    if (this.crudMode !== 'CREATE') {
      this.dto = this._activatedRoute.snapshot.data['hospitalTransfer'];
      this.hYearDto = {...this.dto.transferYear}
      this.year = this.dto.transferYear.year
      this.hmainOpExsist = {...this.dto.transferYear}
      Object.freeze(this.hmainOpExsist);
      this.dto.transferServices.forEach(service => {
        service.items = service.items.map(subItem => ({
          ...subItem,
          paymentTypeMock: !!subItem.paymentAmount
        }));
      });
    }
  }

  ngOnInit(): void {
    this._hospitalTransferYearService.years$().subscribe(years => {
      this.years = years
      if (this.years?.includes(getFiscalYear())) {
        this.year = getFiscalYear()
      }
    })
  }

  add() {

    this._hospitalTransferService.validateHospitalYear(this.hYearDto).subscribe(e => {
      this.dto.transferYear = {...this.hYearDto}
      let hsubs: HospitalTransferServiceDto[] = this.hYearDto.hsubs.map<HospitalTransferServiceDto>(e => ({
        hsub: e,
        items: [{}]
      }))
      //filter
      this.dto.transferServices?.forEach(e => {
        if (!hsubs.some(hsub => hsub.hsub.hcode === e.hsub.hcode)) {
          this.dto.transferServices = this.dto.transferServices.filter(f => f.hsub.hcode !== e.hsub.hcode)
        }
        hsubs = hsubs.filter(f => f.hsub.hcode !== e.hsub.hcode)
      })
      this.dto.transferServices = [...this.dto.transferServices || [], ...hsubs]
    });
  }

  clear() {
    this.hYearDto = {}
  }


  save() {
    this.confirmDialogService.open({content: 'ยืนยันการบันทึก', title: 'แจ้งเตือน'}).pipe(filter(e => !!e))
      .subscribe({
        next: e => {
          this._hospitalTransferService.save(this.dto).subscribe(e => {
            this.toastService.showSuccess('บันทึกข้อมูลสำเร็จ')
            this.router.navigate(['/hospital-transfer']);
          });
        }
      });
  }

  copyToOther() {
    const f = this.dto.transferServices[0]
    this.dto.transferServices.slice(1)
      .forEach(e => {
        e.items = f.items.map(fItem => ({
          serviceType: fItem.serviceType,
          paymentType: fItem.paymentType,
          paymentAmount: fItem.paymentAmount,
          paymentPercent: fItem.paymentPercent,
          endDate: fItem.endDate,
          startDate: fItem.startDate,
          paymentTypeMock: fItem['paymentTypeMock']
        }))
      });
  }

  addHsub() {
    this._transferHsubModalDialogService.add(this.hYearDto.hsubs).subscribe({
      next: (value: HospitalDto[]) => {
        this.hYearDto.hsubs = [...this.hYearDto.hsubs, ...value]
      }
    });
  }

  removeHsub(hsub: HospitalDto) {
    this.hYearDto.hsubs = this.hYearDto.hsubs.filter(e => e.hcode !== hsub.hcode)
  }

  addHsubService(hsubItem: HospitalTransferServiceDto) {
    hsubItem.items = hsubItem.items || []
    let exists: OssServiceTypeHsubDto[] = hsubItem.items.map(e => e.serviceType)
    this.addServiceModalDialogService.add(exists).subscribe({
      next: (value: OssServiceTypeHsubDto[]) => {
        hsubItem.items = hsubItem.items.filter(e => e.serviceType)
        hsubItem.items.push(...value.map<HospitalTransferServiceItemDto>(e => ({
          serviceType: e
        })))
        //default start date and end date from fiscal year only for first service
        if (this.year) {
          let fiscalYear = getFiscalYearDateRange(this.year)
          hsubItem.items[0].startDate ??= fiscalYear.startDate
          hsubItem.items[0].endDate ??= fiscalYear.endDate
        }
      }
    });
  }

  selectPaymentType(subItems: HospitalTransferServiceItemDto) {
    delete subItems.paymentAmount
    delete subItems.paymentPercent
  }

  removeItem(item: HospitalTransferServiceDto, subItems: HospitalTransferServiceItemDto) {
    item.items = item?.items?.filter(e => e !== subItems)
    if(item?.items?.length === 0){
      item.items = [{}]
    }
  }

  protected readonly ADD_ITEM_WARNING = ADD_ITEM_WARNING;
  protected readonly getMinFromStart = getMinFromStart;

  itemToggle(item: HospitalTransferServiceDto) {
    this.dto?.transferServices?.filter(f => f !== item).forEach(e => e['show'] = false)
    item['show'] = !item['show']
  }
}
