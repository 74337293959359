import {Component, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {FileItem, FileUploader, ParsedResponseHeaders} from "ng2-file-upload";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LoadingService} from "../../loader/loading.service";
import {FileUploadService, UPLOAD_URL} from "./file-upload.service";
import {ValidateService} from "../../services/validate.service";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {ImportFileDto} from "../../../../../generated-model/model";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    },
    FileUploadService
  ],
})
export class FileUploadComponent implements ControlValueAccessor, OnInit {

  accessToken: string = undefined;
  uploader: FileUploader;
  uploading: boolean = false;
  response: string;
  validates: string[];
  @Input() size: 'sm-inline' | 'normal' = 'normal';
  @Input() fileConfigItem: any
  onChangeCallBack: (_: any) => void = () => {
  };
  onTouchCallBack: () => void = () => {
  };
  _value: any;
  @Input('id') id: string = 'file';
  @Input('name') name: string = 'file';
  @Input() validateFields: string[] = [];
  @Input('disabled') disabled: boolean = false;
  @Input() fileType : string[] = undefined;
  @Output() uploaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() removed: EventEmitter<any> = new EventEmitter<any>();

  constructor(private renderer: Renderer2,
              private service: FileUploadService,
              private validatorService: ValidateService,
              private modalService: NgbModal,
              private oidcSecurityService: OidcSecurityService,
              private loadingService: LoadingService) {

  }

  get value(): ImportFileDto {

    return this._value;
  }

  set value(val: ImportFileDto) {

    this._value = val;
    this.onChangeCallBack(val);
    this.onTouchCallBack();
  }

  ngOnInit(): void {
    this.oidcSecurityService.getAccessToken()
      .subscribe(accessToken => {
        this.accessToken = accessToken;
        this.uploader = new FileUploader(
          {
            authToken: `Bearer ${this.accessToken}`,
            authTokenHeader: 'Authorization',
            url: UPLOAD_URL,
            allowedFileType: this.fileType
          });
        this.response = '';
        this.uploader.response.subscribe(res => this.response = res);
        this.uploader.onProgressAll = (progress: any) => {
          this.loadingService.show();
        };

        this.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {

          this.loadingService.hide();
          this.uploader.clearQueue();

          this.uploading = false;
          let result = {...this._value, ...JSON.parse(response || "{}")};
          this.value = result;
          this.uploaded.emit(result);
        };

        this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {

          this.validates = [];
          if (status == 400 || status == 413) {
            let error = JSON.parse(response);
            console.log('errors resp = ', error);
            if (error?.errors?.length) {
              this.validates = [...error?.errors?.map((e: any) => e.defaultMessage)];
            } else {
              this.validates = [error.error + "-" + error?.message];
            }
            console.log('errors = ', this.validates);
            this.uploader.clearQueue();
          }
          this.loadingService.hide()
        };

        this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
          console.log('after adding file = ', fileItem);
          console.log('after adding file = ', this.uploader.queue);
          let currFileItem = this.uploader.queue[this.uploader.queue?.length - 1];
          this.uploader.queue = [currFileItem];
        }

        this.uploader.onCompleteAll = () => {

          this.loadingService.hide()
        };
      });
  }

  registerOnChange(fn: any): void {
    this.onChangeCallBack = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchCallBack = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  upload(event: any) {
    event.target.value = '';
    this.validates = [];
    this.uploader.uploadAll();
  }

  reset() {
    this.uploader.clearQueue();
  }

  clear() {
    this.validates = [];
    this.uploader?.clearQueue();
  }

  isUploading() {
    return !!this.uploading;
  }

  preview() {
    window.open(`${UPLOAD_URL}/${this.value.fileName}/preview`, '_blank');
  }

  removeFile() {
    this.value = undefined;
  }

  shortFilename(originalFileName: string | undefined) {
    let length: number = 5
    if (originalFileName && originalFileName.length > length) {
      return originalFileName.substring(0, length) + '...';
    }
    return originalFileName
  }
}
