import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {from, Observable} from "rxjs";
import {TransferHsubModalComponent} from "./transfer-hsub-modal.component";
import {HospitalDto} from "../../../../../../generated-model/model";

@Injectable({providedIn: 'root'})
export class TransferHsubModalDialogService {

  constructor(private modalService: NgbModal) {
  }

  add(exists: HospitalDto[]): Observable<HospitalDto[]> {
    const modalRef: NgbModalRef = this.modalService.open(TransferHsubModalComponent, {
      size: "lg",
      windowClass: 'custom-modal-content',
      backdrop: 'static',
      keyboard: false
    });
    const component: TransferHsubModalComponent = modalRef.componentInstance
    component.crudMode = 'CREATE'
    component.exists = exists
    return from(modalRef.result);
  }
}
