import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {from, Observable} from "rxjs";
import {OssServiceTypeHsubDto} from "../../../../../../generated-model/model";
import {AddServiceModalComponent} from "./add-service-modal.component";

@Injectable({providedIn: 'root'})
export class AddServiceModalDialogService {

  constructor(private modalService: NgbModal) {
  }

  add(exists: OssServiceTypeHsubDto[]): Observable<OssServiceTypeHsubDto[]> {
    const modalRef: NgbModalRef = this.modalService.open(AddServiceModalComponent, {
      size: "xl",
      windowClass: 'custom-modal-content',
      backdrop: 'static',
      keyboard: false
    });
    const component: AddServiceModalComponent = modalRef.componentInstance
    component.crudMode = 'CREATE'
    component.exists = exists
    return from(modalRef.result);
  }
}
