import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HospitalTransferYearManagerService {
  private http = inject(HttpClient);

  constructor() { }

  deleteHsub(hospitalTransferYearId: number, hsubCode: string) {
    return this.http.delete(`${environment.serverUrl}/api/hospital-transfer-year-manager/${hospitalTransferYearId}/hsub/${hsubCode}`)
  }

  delete(hospitalTransferYearId: number) {
    return this.http.delete(`${environment.serverUrl}/api/hospital-transfer-year-manager/${hospitalTransferYearId}`)
  }
}
