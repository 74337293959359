<div class="row mb-3">
  <label class="col-xl-3 col-form-label text-xl-end">กลุ่มบริการ (SERVICE_TYPE_GROUP)</label>
  <div class="col-xl-5">
    <select class="form-select col-lg-5" id="oss-type-group" name="oss-type-group" [(ngModel)]="ossTypeGroups['mock']" (ngModelChange)="selectOssTypeGroups($event)">
      <option selected hidden [ngValue]="undefined">ทั้งหมด</option>
      <option *ngFor="let item of ossTypeGroups" [ngValue]="item"> {{ item }}</option>
    </select>
  </div>
</div>

<div class="row mb-3">
  <label class="col-xl-3 col-form-label text-xl-end ui-require">บริการ (SERVICE_TYPE)</label>
  <div class="col-xl-8">
    <ng-select
      [items]="ossServiceTypeHsubs"
      [multiple]="true"
      [closeOnSelect]="false"
      [searchable]="true"
      placeholder="กรุณาเลือก"
      [disabled]="disabled"
      [clearable]="true"
      appendTo=".scrollable-box"
      [typeahead]="term$"
      (ngModelChange)="valueChange($event)"
      [loading]="typeaheadLoading"
      [(ngModel)]="value"
      (open)="onOpen()"
      #val="ngModel">
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        <span class="ng-value-label">
    {{ item?.type }} : {{ item?.name }}
    </span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        {{ item?.type }} : {{ item?.name }}
      </ng-template>
    </ng-select>
    <gt-error *ngIf="error" [field]="error"></gt-error>
  </div>
</div>
