import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  forwardRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm, NgModel} from "@angular/forms";
import {Province} from "../../../../generated-model/model";
import {finalize, Observable, of, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {IgnoreNullHttpParams} from "../../commons/Ignore-null-http-params";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../shared.module";
import {NgSelectComponent, NgSelectModule} from "@ng-select/ng-select";
import {CustomErrorComponent} from "../../commons/custom-error.component";
import {map} from "rxjs/operators";

@Component({
  selector: 'province-autocomplete',
  templateUrl: './province-autocomplete.component.html',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProvinceAutocompleteComponent),
      multi: true
    }
  ],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm
  }],
  imports: [
    CommonModule,
    NgSelectModule,
    SharedModule,
    CustomErrorComponent
  ]
})
export class ProvinceAutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {

  provinces$: Observable<Province[]> = of([]);
  term$ = new Subject<string>();
  clear$ = new Subject<boolean>();
  typeaheadLoading = false;
  value: Province = undefined;
  @Input('id') id: string = 'province';
  @Input('name') name: string = 'province';
  @Input() disabled: boolean = false;
  @Input('hidden-bkk') hiddenBKK: boolean = false
  @Input() restrictZoneId: string
  @Input() restrictProvince: Province;
  @ViewChild('ngSelectComponent', {static: true, read: NgSelectComponent}) ngSelectComponent: NgSelectComponent;
  @ViewChild('val', {static: true, read: NgModel}) ngModel: NgModel;
  private http = inject(HttpClient);

  //validators
  @Input() required: boolean = false;

  onChange = (value) => {
  };
  onTouched = () => {
  };

  compareWithCode = (o1: Province, o2: Province): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.provinceId == o2.provinceId))
  };

  constructor(private _cd: ChangeDetectorRef,) {
  }

  ngAfterViewInit() {
  }

  ngOnInit(): void {

  }

  fetchData(): Observable<Province[]> {
    this.typeaheadLoading = true;
    return this.http.get<Province[]>(`${environment.serverUrl}/api/province`, {
      params: IgnoreNullHttpParams.fromObject({
        restrictZoneId: this.restrictZoneId,
        size: 100,
        sort: 'provinceName'
      }).toHttpParam()
    }).pipe(
      map(e => {
        return this.hiddenBKK ? e.filter(p => p.provinceId != '1000') : e;
      }),
      finalize(() => this.typeaheadLoading = false)
    );
  }

  writeValue(value: Province) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  valueChange(value: Province) {
    this.onChange(value)
    this.onTouched();
    this._cd.detectChanges()
  }

  ngOnChanges(changes: SimpleChanges): void {
    const restrictProvinceChange = changes['restrictProvince']

    if (restrictProvinceChange?.currentValue) {
      this.ngSelectComponent.handleClearClick()
      this.provinces$ = of([restrictProvinceChange.currentValue])
      this.value = restrictProvinceChange.currentValue
      this.valueChange(undefined)
      return
    }
    const restrictZoneIdChange = changes['restrictZoneId']
    if (restrictZoneIdChange?.currentValue) {
      setTimeout(() => { //prevent ExpressionChangedAfterItHasBeenCheckedError
        this.ngSelectComponent.handleClearClick()
        this.value = undefined
        this.valueChange(undefined)
      })
    }
    this.provinces$ = this.fetchData()
  }
}
