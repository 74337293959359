import {AfterViewInit, Component, HostListener, inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {NgbOffcanvas, OffcanvasDismissReasons} from "@ng-bootstrap/ng-bootstrap";
import {LoginResponse, OidcSecurityService} from "angular-auth-oidc-client";
import {environment} from "../../environments/environment";
import {Version} from "../shared/commons/version.service";
import {distinctUntilChanged, shareReplay} from "rxjs";
import {InboxService} from "../pages/inbox/inbox.service";
import {filter} from "rxjs/operators";
import {UserData} from "../../generated-model/model";
import {organizationName} from "../app.constants";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  public user: UserData;
  public isAuthenticated: boolean = false;
  toggleOffCanvas = false;
  sidebarCollapsed = false;
  closeResult = '';
  version: Version;
  renderer = inject(Renderer2);
  countHra = 0
  environment = environment;

  constructor(private offcanvasService: NgbOffcanvas,
              private oidcSecurityService: OidcSecurityService,
              public inboxService: InboxService) {
  }

  ngAfterViewInit(): void {
    this.recalculateSizeMenu();
    this.recalculateSizeMainContent();
  }

  private recalculateSizeMenu() {
    let sidebarMenu = document.getElementById('sidebar-menu');
    if (sidebarMenu) {
      this.renderer.setStyle(sidebarMenu, 'height', `${window.innerHeight - 65}px`);
      this.renderer.setStyle(sidebarMenu, 'position', 'relative');
    }
  }

  private recalculateSizeMainContent() {
    let mainContent = document.getElementById('main-content');
    if (mainContent) {
      this.renderer.setStyle(mainContent, 'height', `${window.innerHeight - 65}px`);
      this.renderer.setStyle(mainContent, 'position', 'relative');
    }
  }

  ngOnInit() {
    this.oidcSecurityService.userData$
      .subscribe(userData => {
        this.user = userData.userData;
      });
    this.oidcSecurityService.isAuthenticated$
      .subscribe(result => {
        this.isAuthenticated = result.isAuthenticated;
        if (result?.isAuthenticated) {
          this.inboxService.getCountHraRegistration().subscribe(e => {
            this.inboxService.countHra = e
          })
        }

      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // 992px is break point of large devices
    this.toggleOffCanvas = event.target.innerWidth < 992;
    this.sidebarCollapsed = event.target.innerWidth < 992;
    this.recalculateSizeMenu();
    this.recalculateSizeMainContent();
  }

  userInfo() {
    window.open(`${environment.oidcIssuerUrl}/account`, '_blank');
  }

  logout() {
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe(e => {
      console.log(e)
    });
  }

  open(content) {
    this.offcanvasService.open(content, {ariaLabelledBy: 'offcanvas-basic-title'}).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === OffcanvasDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === OffcanvasDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on the backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy(): void {
  }

  login() {
    // this.oidcSecurityService.checkAuth();
    this.oidcSecurityService
      .checkAuth()
      .pipe(
        distinctUntilChanged()
      )
      .subscribe((loginResponse: LoginResponse) => {
        const {isAuthenticated, userData, accessToken, idToken, configId} = loginResponse;
        console.log(loginResponse)
        if (!isAuthenticated) {
          this.oidcSecurityService.authorize();
        }
      });
  }

  protected readonly organizationName = organizationName;
}
