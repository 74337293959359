import {NgForm} from "@angular/forms";
import {OssOrganizationDto} from "../generated-model/model";
import {CustomNgbDateParser} from "./shared/commons/gt-ng-datepicker/custom-ngb-date-parser";
import {DatePipe} from "@angular/common";

export function updateFormAndValidity(form: NgForm): boolean {
  console.log('updateFormAndValidity form ', form);
  if (!form.valid) {
    markInvalidControlsAsDirtyAndTouched(form)
  }
  return form.valid;
}

export function scrollToInvalidElement(formId: string) {
  //for more info aboute the measure see https://stackoverflow.com/questions/24665602/scrollintoview-scrolls-just-too-far
  let y = document.querySelector(`#${formId} .ng-invalid`)?.getBoundingClientRect().top + window.pageYOffset + -80;
  window.scrollTo({top: y});
}

export function markInvalidControlsAsDirtyAndTouched(form: NgForm): void {
  Object.keys(form.controls)
    .map(key => {
      // console.log('form.controls[key], invalid', form.controls[key], form.controls[key].invalid)
      return key;
    })
    .filter(key => form.controls[key].invalid)
    .forEach((key, index) => {
      console.log('form.controls[', key, '], invalid', form.controls[key], form.controls[key].invalid)
      form.controls[key].markAsDirty({onlySelf: false})
      form.controls[key].markAsTouched({onlySelf: false})
      form.controls[key].updateValueAndValidity({emitEvent: true});
    });
}

export type CRUD = 'CREATE' | 'READ' | 'UPDATE' | 'DELETE';

export const DayOfWeekThai: { [id: string]: string } = {
  MONDAY: 'จันทร์',
  TUESDAY: 'อังคาร',
  WEDNESDAY: 'พุธ',
  THURSDAY: 'พฤหัสบดี',
  FRIDAY: 'ศุกร์',
  SATURDAY: 'เสาร์',
  SUNDAY: 'อาทิตย์'
};

export function cloneObject(object: any): any {
  if (!object) {
    return undefined;
  }
  return JSON.parse(JSON.stringify(object))
}

export function mergeObject(obj1: any, obj2: any): any {
  Object.keys(obj2).filter(key => key in obj1).forEach(key => obj1[key] = obj2[key]);
  return obj1;
}

export type PageRequest = { size?: number, page?: number, sort?: string | string[] }
export const compareWithId = (o1: { id: any }, o2: {
  id: any
}): boolean => (o1 == o2) || ((!!o1 && !!o2) && (o1.id === o2.id));
export const compareWithCode = (o1: { code: any }, o2: {
  code: any
}): boolean => (o1 == o2) || ((!!o1 && !!o2) && (o1.code === o2.code));

export type GeoLocation = { lat: number, lon: number };

export function notImplementedYet() {
  alert("not implemented yet.");
}

//because sso user has lowercase authority
export type AuthorityLowerCase =  "nhso" | "zone";

export const DRAFT_CONTRACT_COLLECTION = "DRAFT_CONTRACT_COLLECTION";
export const CHECKLIST_OPERATION_V1 = "th.co.geniustree.nhso.oss.smct.checklist.Checklist_Operation_V1"
export const CHECKLIST_AGREEMENT_V1 = "th.co.geniustree.nhso.oss.smct.checklist.Checklist_Agreement_V1"
export const CHECKLIST_LAW_V1 = "th.co.geniustree.nhso.oss.smct.checklist.Checklist_Law_V1";
export const CONTRACT_TYPE_NO_OF_OPERATION_DOCUMENT = "764362";
export const CONTRACT_TYPE_NO_OF_AGREEMENT_DOCUMENT = "764363";

export const ADD_ITEM_WARNING = "*หากต้องการเพิ่มเครือข่ายหน่วยบริการ ต้องกดปุ่มเพิ่มเพื่อยืนยันเพิ่มเครือข่ายทุกครั้ง";

export function organizationName(organization: OssOrganizationDto): string {
  if (!organization) {
    return "";
  }
  if ("บริษัทจำกัด" === organization.juristicType || "บริษัทมหาชนจำกัด" === organization.juristicType) {
    return "บริษัท " + organization.name;
  } else if ("ห้างหุ้นส่วนจำกัด" === organization.juristicType || "ห้างหุ้นส่วนจำกัดสามัญนิติบุคคล" === organization.juristicType) {
    return organization.juristicType + " " + organization.name;
  }
  //else anything ignore juristicType
  return organization.name;
}

/**
 * ปีงบประมาณ
 */
export function getFiscalYear(): number {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  let fiscalYear;

  // Fiscal year in Thailand starts on October 1st and ends on September 30th
  if (month >= 9) { // October (9) to December (11)
    fiscalYear = year + 543 + 1; // Buddhist year of the next year
  } else {
    fiscalYear = year + 543; // Buddhist year of the current year
  }

  return fiscalYear;
}

export type FiscalYear = { startDate: string, endDate: string, year: number };

/**
 * function is Buddhist year format
 * @param fiscalYear
 */
export function getFiscalYearDateRange(fiscalYear: number): FiscalYear {
  const startDate = new Date(fiscalYear - 543 - 1, 9 , 1);
  const endDate = new Date(fiscalYear - 543, 8, 30);
  return {
    startDate: new DatePipe('en-US').transform(startDate, 'yyyy-MM-dd'),
    endDate: new DatePipe('en-US').transform(endDate, 'yyyy-MM-dd'),
    year: fiscalYear
  };

}

export function getMinFromStart(startDate: any,dateParser: CustomNgbDateParser) {
  if (startDate) {
    // cast to date
    let date = new Date(startDate);
    date = new Date( date.getFullYear() + 543, date.getMonth(), date.getDate() + 1);
    return dateParser.parse(date.toISOString());
  }
  return null;
}

