import {Injectable} from "@angular/core";
import {Authority} from "../../generated-model/model";
import {filter} from "rxjs/operators";
import {environment} from "../../environments/environment";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {map, Observable} from "rxjs";
import {AuthorityLowerCase} from "../app.constants";

@Injectable({providedIn: "root"})
export class SecurityAuthorityService {

  constructor(public oidc: OidcSecurityService) {
  }

  public hasAnyAuthority(checkAuthorities: (Authority[] | AuthorityLowerCase[])): Observable<boolean> {
    return this.oidc.userData$
      .pipe(filter(e => e?.userData),
        map(u => {
          let userAuthorities: Authority[] = u.userData?.resource_access?.[environment.clientId]?.roles || [];
          let realmRole: Authority[] = u.userData?.realm_access?.roles || [];
          userAuthorities = [...userAuthorities, ...realmRole];
          if (this.hasAny(checkAuthorities, userAuthorities)) {
            return true
          } else {
            return false
          }
        }))
  }

  private hasAny(authorityList: (Authority[] | AuthorityLowerCase[]), authorities: string[] = []): boolean {
    if (authorities) {
      let hasAuthority = authorities.some(e => authorityList.some(ex => e == ex));
      return hasAuthority;
    } else {
      return false;
    }
  }
}
