<ng-container *ngIf="size == 'normal'">
  <input [id]="id"
         class="form-control d-none"
         [attr.name]="name"
         type="file"
         ng2FileSelect
         #file
         [uploader]="uploader"
         (change)="upload($event)">
  <ng-container *ngIf="!isUploading()"
                class="small file-upload-label {{disabled?'disabled':''}} text-truncate vertical-align-middle"
                style="max-width: 250px;">
    <label [for]="!disabled? id: ''"
           role="button"
           (click)="file.click()"
           class="btn btn-default bg-cornsilk border me-2">
      <i class="bi bi-upload me-2"></i>
      <span *ngIf="!value">เลือกไฟล์...</span>
      <span *ngIf="value" class="small text-truncate vertical-align-middle" style="max-width: 150px;">
      {{value?.originalFileName}}
    </span>
    </label>
  </ng-container>
  <ng-container *ngIf="isUploading()">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    กำลังอัพโหลด...
  </ng-container>
  <span *ngIf="value" (click)="preview()" class="d-inline-block preview-button align-middle text-muted text-truncate"
        style="max-width: 150px;">
    <span class="ms-2">ดู</span>
  </span>
  <span *ngIf="!value" class="d-inline-block align-middle text-muted text-truncate" style="max-width: 150px;">ยังไม่ได้เลือก</span>
</ng-container>

<ng-container *ngIf="size == 'sm-inline'" class="text-nowrap">
  <input [id]="id"
         class="form-control d-none"
         [attr.name]="name"
         type="file"
         ng2FileSelect
         [required]
         #filesm
         [uploader]="uploader"
         (change)="upload($event)">
  <ng-container *ngIf="!isUploading()"
                class="small file-upload-label {{disabled?'disabled':''}} text-truncate vertical-align-middle"
                style="max-width: 250px;">
    <label *ngIf="!disabled" role="button" ngbTooltip="{{value?.originalFileName || 'เลือกไฟล์...' }}" (click)="filesm.click()" class="btn btn-default btn-sm file-select border text-truncate" style="max-width: 100px; ">
      <i class="bi bi-upload me-1"></i>
      {{shortFilename(value?.originalFileName) || 'เลือกไฟล์...'}}
    </label>
    <a *ngIf="disabled && value?.originalFileName"  ngbTooltip="{{value?.originalFileName}}" role="button" (click)="preview()" class="preview-button text-truncate" style="max-width: 100px;">
      <i class="bi bi-paperclip me-1"></i>
      {{shortFilename(value?.originalFileName)}}
    </a>
    <a *ngIf="disabled && !value?.originalFileName" role="button" class="preview-button no-file-selected text-truncate text-muted" style="max-width: 100px;">
      <i>--ไม่มีไฟล์แนบ--</i>
    </a>
    <ng-container *ngIf="value?.originalFileName && !disabled">
      <span (click)="preview()" style="cursor: pointer;" role="button" class="mx-2">
        ดู
      </span>
      <i *ngIf="!disabled" (click)="removeFile()" style="cursor: pointer;" class="bi bi-trash"></i>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isUploading()">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    กำลังอัพโหลด...
  </ng-container>
</ng-container>
<custom-error [error]="validates[0]" *ngIf="validates?.length">{{validates[0]}}</custom-error>
