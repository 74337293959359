import {LogLevel} from "angular-auth-oidc-client";

export const environment = {
  production: false,
  serverUrl: 'https://mastercup.geniustree.io',
  oidcIssuerUrl: 'https://sso.nhso.geniustree.io/realms/nhso',
  clientId: 'mastercup',
  ngOidcLogLevel: LogLevel.None,
  portalUrl: 'https://portal.nhso.geniustree.io',
  hospitalNetworkModule: false
};
