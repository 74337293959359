import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {
  HospitalTransferDto,
  HospitalTransferSearchParam,
  HospitalTransferYearDto,
  Page, ProvinceTransferDto
} from "../../../../generated-model/model";
import {map, Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {PageRequest} from "../../../app.constants";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {saveAs} from "file-saver";

@Injectable({
  providedIn: 'root'
})
export class HospitalTransferService {
  private http = inject(HttpClient);

  constructor() {
  }

  validateHospitalYear(item: HospitalTransferYearDto): Observable<HospitalTransferYearDto> {
    return this.http.post<HospitalTransferYearDto>(`${environment.serverUrl}/api/hospital-transfer/validate-hospital-year`, item);
  }

  save(item: HospitalTransferDto): Observable<HospitalTransferDto> {
    return this.http.post<HospitalTransferDto>(`${environment.serverUrl}/api/hospital-transfer/save`, item);
  }
  saveTransferProvince(item: ProvinceTransferDto): Observable<void> {
    return this.http.post<void>(`${environment.serverUrl}/api/hospital-transfer/save-transfer-province`, item).pipe(
      map(() => {})
    );
  }

  toggleStatus(hospitalTransferId: number): Observable<void> {
    return this.http.put<void>(`${environment.serverUrl}/api/hospital-transfer/toggle-status/${hospitalTransferId}`, null);
  }

  updateToCompleteAll(ids: number[]): Observable<void> {
    return this.http.put<void>(`${environment.serverUrl}/api/hospital-transfer/complete-all-status`, ids);
  }

  search(request: HospitalTransferSearchParam, pageRequest: PageRequest): Observable<Page<HospitalTransferDto>> {
    if (request.hospitalTransferYearDto) {
      delete request.hospitalTransferYearDto.hmainOp.province
      delete request.hospitalTransferYearDto.hsubs
    }
    let param = IgnoreNullHttpParams.fromObject({...request, ...pageRequest}).toHttpParam();
    return this.http.get<Page<HospitalTransferDto>>(`${environment.serverUrl}/api/hospital-transfer/search`, {params: param});
  }

  findById(id: number): Observable<HospitalTransferDto> {
    return this.http.get<HospitalTransferDto>(`${environment.serverUrl}/api/hospital-transfer/${id}`);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${environment.serverUrl}/api/hospital-transfer/${id}`);
  }

  export(request: HospitalTransferSearchParam){
    if (request.hospitalTransferYearDto) {
      delete request.hospitalTransferYearDto.hmainOp.province
      delete request.hospitalTransferYearDto.hsubs
    }
    let params = IgnoreNullHttpParams.fromObject({reportPath: 'transfer_hospital_report.jasper', mimeType: 'xlsx', ...request})
    this.http.get(`${environment.serverUrl}/api/hospital-transfer/export`, {params: params.toHttpParam(), responseType: 'blob'})
      .subscribe(e => {
        saveAs(new Blob([e]), `รายงานรพสตถ่ายโอน-${new Date().toLocaleDateString("en-CA")}.xlsx`)
      })
  }
}
