import {Component, inject, OnInit} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SelectTodoComponent} from "../../../shared/components/select-todo/select-todo.component";
import {SharedModule} from "../../../shared/shared.module";
import {LoadingService} from "../../../shared/commons/loader/loading.service";
import {HttpClient} from "@angular/common/http";
import {DecimalPipe, NgClass, NgIf} from "@angular/common";
import {
  HospitalTransferYearImportDto,
  ImportFile,
  Page
} from "../../../../generated-model/model";
import {ToastService} from "../../../shared/commons/toast/toast-service";
import {ConfirmDialogService} from "../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {HospitalTransferYearService} from "../services/hospital-transfer-year.service";
import {filter} from "rxjs/operators";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {environment} from "../../../../environments/environment";
import {saveAs} from "file-saver";
import {CustomUserDataResult} from "../../../openid-connect-userinfo";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {SecurityAuthorityService} from "../../../security/security-authority.service";
@Component({
  selector: 'app-hospital-transfer-year-import',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SelectTodoComponent,
    SharedModule,
    DecimalPipe,
    NgIf,
    NgClass
  ],
  templateUrl: './hospital-transfer-year-import.component.html',
  styleUrl: './hospital-transfer-year-import.component.scss'
})
export class HospitalTransferYearImportComponent implements OnInit{

  loadingService = inject(LoadingService);
  http = inject(HttpClient);
  toastService = inject(ToastService);
  confirmDialogService = inject(ConfirmDialogService);
  hospitalTransferYearService = inject(HospitalTransferYearService);
  pageData: Page<any> = {number: 0, size: 10, totalElements: 0};
  importDtos: HospitalTransferYearImportDto[] = []
  importFile: ImportFile = {};
  zoneCode: string;
  private _oidcSecurityService = inject(OidcSecurityService);
  private securityAuthorityService = inject(SecurityAuthorityService);

  downloadFileTemplate() {
    this.hospitalTransferYearService.downloadFileTemplate();
  }

  view($event: ImportFile) {
    if ($event.fileName) {
      this.hospitalTransferYearService.excelMapper($event).subscribe(res => {
        this.importDtos = res
        this.pageData.totalElements = res.length
      })
    }
  }

  save() {
    if (this.checkDataImportToDisabled()) {
      return;
    }

    this.confirmDialogService.open({content: 'ยืนยันการบันทึก', title: 'แจ้งเตือน'}).pipe(filter(e => !!e))
      .subscribe({
        next: e => {
          this.hospitalTransferYearService.saveImport(this.importFile).subscribe(res => {
            this.toastService.showSuccess("บันทึกข้อมูลเรียบร้อย ตรวจสอบได้ที่เมนู ข้อมูลรพ.สต. ถ่ายโอน")
            this.importFile = {}
            this.pageData = {number: 0, size: 10, totalElements: 0};
            this.importDtos = []
          })
        }
      });
  }

  checkDataImportToDisabled(): boolean {
    if (this.importDtos.length === 0) {
      return true;
    }
    // Check if any importDtos item has empty hmainOpName
    let hasEmptyHmainOpName = this.importDtos.some(item => !item.hmainOpName);

    // Check if any hsubs item has empty hsubName
    let hasEmptyHsubName = this.importDtos.flatMap(e => e.hsubs)?.some(e => !e.hsubName);

    // Return true if either hmainOpName or hsubName is empty
    return hasEmptyHmainOpName || hasEmptyHsubName;
  }

  checkItemDisabled(item: HospitalTransferYearImportDto): boolean {
    return !item.hmainOpName || item.hsubs?.some(e => !e.hsubName);
  }

  export(mimeType: string) {
    let params = IgnoreNullHttpParams.fromObject({
      reportPath: 'transfer_hospital_year.jasper',
      mimeType: mimeType,
      zoneCode: this.zoneCode,
    })
    this.http.get(`${environment.serverUrl}/api/report/export`, {params: params.toHttpParam(), responseType: 'blob'})
      .subscribe(e => {
        saveAs(new Blob([e]), `หน่วยบริการแม่ข่าย-${new Date().toLocaleDateString('en-CA')}.${mimeType}`)
      })
  }

  ngOnInit(): void {
    this._oidcSecurityService.userData$.subscribe((userdata: CustomUserDataResult) => {
      this.securityAuthorityService.hasAnyAuthority(['zone']).subscribe(hasAuthority => {
        if (hasAuthority) {
          this.zoneCode = userdata?.userData?.organization?.id?.padStart(2, "0");
        }
      });
    });
  }
}
