import {
  AfterViewInit,
  Component,
  forwardRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {District, SubDistrict} from "../../../../generated-model/model";
import {debounceTime, distinctUntilChanged, finalize, Observable, of, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {IgnoreNullHttpParams} from "../../commons/Ignore-null-http-params";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../shared.module";
import {NgSelectComponent, NgSelectModule} from "@ng-select/ng-select";

@Component({
  selector: 'subdistrict-autocomplete',
  templateUrl: './subdistrict-autocomplete.component.html',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SubdistrictAutocompleteComponent),
      multi: true
    }
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    SharedModule
  ]
})
export class SubdistrictAutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {

  subDistricts: SubDistrict[] = []
  typeaheadLoading = false;
  value: SubDistrict = undefined;
  @Input('id') id: string = 'subdistrict';
  @Input('name') name: string = 'subdistrict';
  @Input() disabled: boolean = false;
  @Input() restrictDistrictCode: string;
  @ViewChild('ngSelectComponent', {static: true, read: NgSelectComponent}) ngSelectComponent: NgSelectComponent;
  private http = inject(HttpClient);
  onChange = (value) => {
  };
  onTouched = () => {
  };
  compareWithCode = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.code == o2.code))
  };

  constructor() {
  }

  ngAfterViewInit() {
  }

  ngOnInit(): void {

  }

  fetchData(): void {
    this.http.get<SubDistrict[]>(`${environment.serverUrl}/api/subdistrict`, {
      params: IgnoreNullHttpParams.fromObject({
        restrictDistrictCode: this.restrictDistrictCode
      }).append("sort","name").toHttpParam()
    }).pipe(finalize(() => this.typeaheadLoading = false)).subscribe(e => {
      this.subDistricts = e
    });
  }

  writeValue(value: SubDistrict) {
    if (value !== this.value) {
      this.value = value;
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  valueChange(value: SubDistrict) {
    this.onChange(value);
    this.onTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const restrictDistrictCodeChange = changes['restrictDistrictCode'];
    if (restrictDistrictCodeChange) {
      setTimeout(() => {
        this.ngSelectComponent.handleClearClick()
        this.valueChange(restrictDistrictCodeChange?.currentValue)
        if (restrictDistrictCodeChange?.currentValue) {
          this.fetchData()
        }
      })
    }
  }
}
