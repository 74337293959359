<div class="card border mb-3">
  <form>
    <div class="card-body">
      <div class="row">
        <label for="file" class="col-xl-2 col-form-label text-xl-end">เลือกไฟล์</label>
        <div class="col-xl-4">
          <app-file-upload id="file" name="file"
                           [(ngModel)]="importFile"
                           (ngModelChange)="view($event)"
                           size="sm-inline"></app-file-upload>
        </div>
      </div>
      <div class="row">
        <div class="offset-xl-2 col">
          <button (click)="downloadFileTemplate()" [disabled]="loadingService.isLoading()" type="button"
                  class="btn btn-sm btn-link px-0">
            <i class="bi bi-table me-1"></i>
            Download File Template
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="card border">
  <div class="card-body">
    <div class="row">
      <div class="col">
        <div class="table-responsive">
          <table class="table table-sm table-striped table-borderless caption-top border">
            <caption>
              <button type="button" (click)="export('xlsx')" class="btn btn-default ms-1 float-end">
                <img src="assets/icons/file-excel-flat.png" alt="" height="20"> ส่งออกข้อมูล .xlsx
              </button>
            </caption>
            <thead class="color-black font-weight-light">
            <tr>
              <th>รายการที่</th>
              <th>ปีงบประมาณ</th>
              <th>หน่วยบริการแม่ข่าย (HMAIN_OP)</th>
              <th>รพ.สต. ถ่ายโอน</th>
              <th>รายละเอียด (กรณีไม่ผ่านการตรวจสอบ)</th>
            </tr>
            </thead>
            <tbody class="table-group-divider border">
            <tr *ngIf="!importDtos?.length">
              <td class="text-muted text-center" colspan="5">
                <i class="bi bi-inbox"></i><br>
                ไม่พบข้อมูลหน่วยบริการ
              </td>
            </tr>
              @for (item of importDtos; track item; let i = $index) {
                <tr>
                  <td class="text-center">{{ i + 1 }}</td>
                  <td class="text-center">{{ item?.year }}</td>
                  <td>{{ item?.hmainOpCode }} : {{ item.hmainOpName }}</td>

                  <td>
                    @for (hsub of item?.hsubs; track $index) {
                      <ul>{{ hsub.hsubCode }} : {{ hsub.hsubName }}</ul>
                    }
                  </td>
                  <td>
                    <span *ngIf="checkItemDisabled(item)" class="text-danger">ไม่พบข้อมูลหน่วยบริการในระบบ</span>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6">
        <div class="text-muted text-sm-start">
          แสดง {{ (pageData?.totalElements ? 1 : 0) | number }}
          ถึง {{ pageData?.totalElements }}
          จากทั้งหมด {{ pageData?.totalElements | number }} รายการ
        </div>
      </div>
      <div class="col-xl-6">
        <ngb-pagination class="float-end"
                        [maxSize]="5"
                        [rotate]="true"
                        [ellipses]="false"
                        [boundaryLinks]="true"
                        [page]="pageData?.number + 1"
                        (pageChange)="$event"
                        [pageSize]="pageData?.size"
                        [collectionSize]="pageData?.totalElements"></ngb-pagination>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button type="button" (click)="save()" class="btn btn-primary btn-custom"
                [disabled]="checkDataImportToDisabled()">
          <i class="bi bi-floppy2-fill me-2"></i>
          บันทึกข้อมูล
        </button>
        <button type="submit" class="btn btn-default btn-custom ms-2">
          ยกเลิก
        </button>
      </div>
    </div>
  </div>
</div>
