<nav aria-label="breadcrumb">
  <ol class="breadcrumb breadcrumb-chevron p-2 bg-body-tertiary rounded-3">
    @for (breadcrumb of breadcrumbs|async;track breadcrumb;let first = $first;let last = $last;) {
      <li *ngIf="first" class="breadcrumb-item">
        <a class="link-body-emphasis" routerLink="landing">
          <i class="bi bi-house"></i>
        </a>
      </li>
      <li *ngIf="!last" class="breadcrumb-item">
        <a class="link-body-emphasis fw-semibold text-decoration-none" [routerLink]="breadcrumb.url">
          {{ breadcrumb.label.title }}
        </a>
      </li>
      <li *ngIf="last" class="breadcrumb-item active" aria-current="page">
        {{ breadcrumb.label.title }}
      </li>
    }
  </ol>
</nav>
