import {
  AfterViewInit,
  Component, EventEmitter, forwardRef,
  inject,
  Input,
  OnInit, Output,
  ViewChild
} from '@angular/core';
import {debounceTime, distinctUntilChanged, Observable, Subject} from "rxjs";
import {NgSelectModule} from "@ng-select/ng-select";
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl
} from "@angular/forms";
import {OssServiceTypeHsubDto} from "../../../../generated-model/model";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {NgForOf, NgIf} from "@angular/common";
import {SharedCommonModule} from "../../commons/shared-common.module";

@Component({
  selector: 'oss-service-type-hsub-autocomplete',
  standalone: true,
  imports: [
    NgSelectModule,
    NgForOf,
    NgIf,
    FormsModule,
    SharedCommonModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OssServiceTypeHsubAutocompleteComponent),
      multi: true
    }
  ],
  templateUrl: './oss-service-type-hsub-autocomplete.component.html',
  styleUrl: './oss-service-type-hsub-autocomplete.component.scss'
})
export class OssServiceTypeHsubAutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit {

  allOssServiceTypeHsub: OssServiceTypeHsubDto[] = []
  ossServiceTypeHsubs: OssServiceTypeHsubDto[] = []
  term$ = new Subject<string>();
  typeaheadLoading = false;
  _value: OssServiceTypeHsubDto[] = undefined;
  @Input('id') id: string = 'oss-service-type-hsub';
  @Input('name') name: string = 'oss-service-type-hsub';
  @Input() disabled: boolean = false;
  private http = inject(HttpClient);
  ossTypeGroups: Set<string> = new Set();
  @Output() ossServiceType: EventEmitter<OssServiceTypeHsubDto[]> = new EventEmitter();
  @Input("error") error: string;
  @Input()
  exists: OssServiceTypeHsubDto[] = [];

  onChange: any = () => {
  }
  onTouched = () => {
  };
  private onChangeCallback: (_: OssServiceTypeHsubDto[]) => void = () => {
  };
  @ViewChild(NgControl) innerNgControl: NgControl;

  constructor() {
  }

  ngAfterViewInit() {
  }

  ngOnInit(): void {

    this.fetchData().subscribe(data => {
      data = data.map(value => ({...value, 'disabled': this.exists.some(e => e?.id === value?.id)}));
      this.allOssServiceTypeHsub = data;
      Object.freeze(this.allOssServiceTypeHsub);
      this.ossTypeGroups = new Set(this.allOssServiceTypeHsub.map(value => value.groupName));
      this.ossServiceTypeHsubs = data;
    });

    this.term$
      .pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe(term => {
        this.typeaheadLoading = true;
        this.ossServiceTypeHsubs = this.allOssServiceTypeHsub.filter(value => (value.name.includes(term) || value.type.includes(term)));
        this.typeaheadLoading = false;
      });
  }

  fetchData(): Observable<OssServiceTypeHsubDto[]> {
    return this.http.get<OssServiceTypeHsubDto[]>(`${environment.serverUrl}/api/oss-service-type-hsub/all`);
  }

  writeValue(value: OssServiceTypeHsubDto[]) {
    this.value = value;

  }

  get value(): OssServiceTypeHsubDto[] {
    return this._value;
  }

  set value(value: OssServiceTypeHsubDto[]) {
    if (value !== this._value) {
      this._value = value;
      this.onChangeCallback(value);

    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  valueChange(value: OssServiceTypeHsubDto[]) {
    this.onChange(value);
    this.onTouched();
    this.ossServiceType.emit(value);
  }

  onOpen() {

  }

  selectOssTypeGroups(group: string) {
    this.value ??= [];
    let itemInGroups = this.allOssServiceTypeHsub.filter(value => (value.groupName === group));
    let ids = this.value.map(value => value.id);
    let items = itemInGroups.filter(value => !ids.includes(value.id));
    items = items.filter(value => !this.exists?.some(e => e?.id === value?.id));
    this.value = [...this.value, ...items];
    this.valueChange(this.value);
  }
}
