import {Injectable} from '@angular/core';
import {HttpClient, HttpContext} from "@angular/common/http";
import {Catm, CatmDto, CreateMasterCupRequestDto, CreateMasterCupRequestItemDto, HraRegistrationDto, HraRegistrationSearchRequest, MastercupDto, MastercupSearchRequest, Page, Type, ZoneDto} from "../../../generated-model/model";
import {PageRequest} from "../../app.constants";
import {Observable, shareReplay} from "rxjs";
import {IgnoreNullHttpParams} from "../../shared/commons/Ignore-null-http-params";
import {IGNORE_LOADING} from "../../shared/commons/loader/loading-interceptor";

@Injectable({
  providedIn: 'root'
})
export class InboxService {
  private httpContext = new HttpContext().set(IGNORE_LOADING, true);
  countHra=0;

  constructor(private http: HttpClient) {
  }


  search(request: HraRegistrationSearchRequest, pageRequest: PageRequest): Observable<Page<HraRegistrationDto>> {
    let param = IgnoreNullHttpParams.fromObject({...request, ...pageRequest}).toHttpParam();
    return this.http.get(`/public/api/hra-registration`, {params: param})
  }

  getAllServiceTypeSelectList(): Observable<Type[]> {
    return this.http.get<Type[]>(`/public/api/type`);
  }

  findByHraRegistrationId(id: number): Observable<HraRegistrationDto> {
    return this.http.get<HraRegistrationDto>(`/public/api/hra-registration/${id}`);
  }

  findMooBySubDistrictCode(subDistrictCode: string): Observable<Catm[]> {
    return this.http.get<Catm[]>(`/public/api/catm/${subDistrictCode}`);

  }

  saveCreateMasterCupRequest(item: CreateMasterCupRequestDto, id: number): Observable<CreateMasterCupRequestDto[]> {
    return this.http.post<CreateMasterCupRequestDto[]>(`/api/create-mcup-request/${id}`, item);

  }

  createMasterCup(item: CreateMasterCupRequestDto): Observable<CreateMasterCupRequestDto[]> {
    return this.http.post<CreateMasterCupRequestDto[]>(`/api/create-mcup-request`, item);

  }

  findCreateMCupRequestByHraRegistrationId(id: number): Observable<HraRegistrationDto> {
    return this.http.get<HraRegistrationDto>(`/api/create-mcup-request/${id}`);
  }

  getCountHraRegistration(): Observable<number> {
    return this.http.get<number>(`/api/create-mcup-request/count`, {context: this.httpContext});
  }

  validateItem(item: CreateMasterCupRequestItemDto): Observable<CreateMasterCupRequestItemDto> {
    return this.http.post<CreateMasterCupRequestItemDto>(`/api/create-mcup-request/validate-item`, item);
  }

  findAllZone(): Observable<ZoneDto[]> {
    return this.http.get <ZoneDto[]>(`/api/zone/find-all`);
  }

}
