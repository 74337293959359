import {Component, inject, OnInit} from '@angular/core';
import {SharedModule} from "../../shared/shared.module";
import {NgForOf, NgIf} from "@angular/common";
import {HospitalTransferYearService} from "../hospital-transfer/services/hospital-transfer-year.service";
import {
  ProvinceAutocompleteComponent
} from "../../shared/components/province-autocomplete/province-autocomplete.component";
import {
  HospitalDto,
  HospitalTransferServiceItemDto,
  OssServiceTypeHsubDto,
  ProvinceDto,
  ProvinceTransferDto
} from "../../../generated-model/model";
import {CustomUserDataResult} from "../../openid-connect-userinfo";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {SecurityAuthorityService} from "../../security/security-authority.service";
import {getFiscalYear, getFiscalYearDateRange, getMinFromStart} from "../../app.constants";
import {DigitOnlyModule} from "@uiowa/digit-only";
import {
  AddServiceModalDialogService
} from "../hospital-transfer/detail/components/add-service-modal/add-service-modal-dialog-service.service";
import {paidTypes} from '../hospital-transfer/detail/hospital-transfer-detail.component';
import {filter} from "rxjs/operators";
import {ConfirmDialogService} from "../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {HospitalTransferService} from "../hospital-transfer/services/hospital-transfer.service";
import {Router} from "@angular/router";
import {ToastService} from "../../shared/commons/toast/toast-service";
import {HsubModalDialogService} from "./components/hsub-modal/hsub-modal-dialog.service";
import {CustomNgbDateParser} from "../../shared/commons/gt-ng-datepicker/custom-ngb-date-parser";

@Component({
  selector: 'app-province-transfer',
  standalone: true,
  imports: [
    SharedModule,
    NgForOf,
    ProvinceAutocompleteComponent,
    NgIf,
    DigitOnlyModule
  ],
  templateUrl: './province-transfer.component.html',
  styleUrl: './province-transfer.component.scss'
})
export class ProvinceTransferComponent implements OnInit {
  private _hospitalTransferYearService = inject(HospitalTransferYearService);
  private _oidcSecurityService = inject(OidcSecurityService);
  private _securityAuthorityService = inject(SecurityAuthorityService);
  private _addServiceModalDialogService = inject(AddServiceModalDialogService);
  private _confirmDialogService = inject(ConfirmDialogService);
  private _hospitalTransferService = inject(HospitalTransferService);
  private _toastService: ToastService = inject(ToastService);
  private _hsubModalDialogService: HsubModalDialogService = inject(HsubModalDialogService);
  public dateParser: CustomNgbDateParser = inject(CustomNgbDateParser);
  years: number[] = []
  request: ProvinceTransferDto = {}
  zoneId: string
  paidTypes: number[] = paidTypes

  constructor(private _router: Router) {
  }

  ngOnInit(): void {
    this._hospitalTransferYearService.years$().subscribe(years => {
      this.years = years
      if (this.years?.includes(getFiscalYear())) {
        this.request.year = getFiscalYear()
      }
    })
    this._oidcSecurityService.userData$.subscribe((userdata: CustomUserDataResult) => {
      this._securityAuthorityService.hasAnyAuthority(['zone']).subscribe(hasAuthority => {
        if (hasAuthority) {
          this.zoneId = userdata?.userData?.organization?.id?.padStart(2, "0");
        }
      });
    });
  }

  save() {
    let content = 'ยืนยันการบันทึก <span class="text-danger fw-bold">กรณีมีการแก้ไขต้องแก้ไขรายแม่ข่าย</span>'
    this._confirmDialogService.open({content: content, title: 'แจ้งเตือน', isInnerHTML: true}).pipe(filter(e => !!e))
      .subscribe({
        next: e => {
          this._hospitalTransferService.saveTransferProvince(this.request).subscribe(e => {
            this._toastService.showSuccess('บันทึกข้อมูลสำเร็จ')
            this._router.navigate(['/hospital-transfer']);
          });
        }
      });
  }

  select(year: number, province: ProvinceDto) {
    if (year && province) {
      this._hospitalTransferYearService.availableHmainOp(year, province.provinceId).subscribe({
        next: data => {
          this.request.hmainOps = data
        }
      })
    } else {
      this.request.hmainOps = []
    }
  }


  addService() {
    this.request.items ??= []
    let exists: OssServiceTypeHsubDto[] = this.request.items.map(e => e.serviceType)
    this._addServiceModalDialogService.add(exists).subscribe({
      next: (value: OssServiceTypeHsubDto[]) => {
        this.request.items.push(...value.map<HospitalTransferServiceItemDto>(e => ({
          serviceType: e
        })))
        //default start date and end date from fiscal year only for first service
        if (this.request.year) {
          let fiscalYear = getFiscalYearDateRange(this.request.year)
          this.request.items[0].startDate ??= fiscalYear.startDate
          this.request.items[0].endDate ??= fiscalYear.endDate
        }
      }
    });
  }

  selectPaymentType(item: HospitalTransferServiceItemDto) {
    delete item.paymentAmount
    delete item.paymentPercent
  }

  removeItem(item: HospitalTransferServiceItemDto) {
    this.request.items = this.request.items.filter(e => e !== item)
  }

  copyToOther() {
    const f = this.request.items[0]
    this.request.items.slice(1).forEach(e => {
      e.paymentType = f.paymentType
      e.paymentAmount = f.paymentAmount
      e.paymentPercent = f.paymentPercent
      e.endDate = f.endDate
      e.startDate = f.startDate
      e['paymentTypeMock'] = f['paymentTypeMock']
    })
  }

  reset() {
    this.request.items = this.request.items.map<HospitalTransferServiceItemDto>(e => ({serviceType: e.serviceType}))
  }

  viewHsub(year: number, h: HospitalDto) {
    this._hsubModalDialogService.view(h.hcode, year)
  }

  protected readonly getMinFromStart = getMinFromStart;
}
