import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PageRequest} from "../../../app.constants";
import {HospitalDto, Page, TransferHsubSearchRequest} from "../../../../generated-model/model";
import {Observable} from "rxjs";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HospitalHsubService {
  private http = inject(HttpClient);
  constructor() { }

  search(searchParam: TransferHsubSearchRequest, pageRequest: PageRequest): Observable<Page<HospitalDto>> {
    const param = IgnoreNullHttpParams.fromObject({...searchParam, ...pageRequest}).toHttpParam()
    return this.http.get<Page<HospitalDto>>(`${environment.serverUrl}/api/hospital-hsub/search`, {params: param});
  }
}
