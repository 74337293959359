import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  forwardRef,
  inject, Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, NgForm} from "@angular/forms";
import {District, Province} from "../../../../generated-model/model";
import {finalize, Observable, of, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {IgnoreNullHttpParams} from "../../commons/Ignore-null-http-params";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../shared.module";
import {NgSelectComponent, NgSelectModule} from "@ng-select/ng-select";
import {CustomErrorComponent} from "../../commons/custom-error.component";

@Component({
  selector: 'district-autocomplete',
  templateUrl: './district-autocomplete.component.html',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DistrictAutocompleteComponent),
      multi: true
    }
  ],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: NgForm
  }],
  imports: [
    CommonModule,
    NgSelectModule,
    SharedModule,
    CustomErrorComponent
  ]
})
export class DistrictAutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {

  districts: District[] = []
  term$ = new Subject<string>();
  clear$ = new Subject<boolean>();
  typeaheadLoading = false;
  value: District = undefined;
  @Input('id') id: string = 'district';
  @Input('name') name: string = 'district';
  @Input() disabled: boolean = false;
  @Input() restrictProvinceId: string
  @ViewChild(NgControl) innerNgControl: NgControl;
  @ViewChild('ngSelectComponent', {static: true, read: NgSelectComponent}) ngSelectComponent: NgSelectComponent;
  private http = inject(HttpClient);

  //validators
  private ngControl: NgControl;
  @Input() required: boolean = false;
  onChange = (value) => {
  };
  onTouched = () => {
  };

  compareWithCode = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.code == o2.code))
  };

  constructor(private inj: Injector,private _cd: ChangeDetectorRef,) {
  }


  ngAfterViewInit() {
  }

  ngOnInit(): void {

  }

  writeValue(value: District) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  valueChange(value: District) {
    console.log(value)
    this.onChange(value)
    this.onTouched();
    this._cd.detectChanges()
  }

  fetchData(): void {
    this.http.get<District[]>(`${environment.serverUrl}/api/district`, {
      params: IgnoreNullHttpParams.fromObject({restrictProvinceId: this.restrictProvinceId, size: 100})
        .append("sort","name")
        .toHttpParam()
    }).pipe(finalize(() => this.typeaheadLoading = false)).subscribe(e => {
      this.districts = e
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes)
    const restrictProvinceIdChange = changes['restrictProvinceId'];
    if (restrictProvinceIdChange) {
      setTimeout(() => {
        this.ngSelectComponent.handleClearClick()
        this.valueChange(restrictProvinceIdChange?.currentValue)
        if (restrictProvinceIdChange?.currentValue) {
          this.fetchData()
        }
      })
    }
  }
}
