<form #form="ngForm" class="form-validate" (ngSubmit)="submit()">
  <div class="modal-header">
    <h6 class="modal-title">เลือกรพ.สต. ถ่ายโอน (HSUB)</h6>
    <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body overflow-hidden">

    <div class="row mb-3">
      <label for="zone" class="col-xl-2 col-form-label text-end">เขต</label>
      <div class="col-xl-6">
        <select class="form-select col-lg-5" id="zone" name="zone" [(ngModel)]="param.zone">
          <option selected hidden [ngValue]="undefined">ทั้งหมด</option>
          <option *ngFor="let zone of zones" [ngValue]="zone"> {{ zone?.name }}</option>
        </select>
      </div>
    </div>
    <div class="row mb-3">
      <label for="province" class="col-xl-2 col-form-label text-end">จังหวัด</label>
      <div class="col-xl-6">
        <province-autocomplete id="province" name="province" [restrictZoneId]="param?.zone?.id"
                               [(ngModel)]="param.province"></province-autocomplete>
      </div>
    </div>
    <div class="row mb-3">
      <label for="district" class="col-xl-2 col-form-label text-end">อำเภอ</label>
      <div class="col-xl-6">
        <district-autocomplete id="district" name="district"
                               [restrictProvinceId]="param.province?.provinceId"
                               [(ngModel)]="param.district"></district-autocomplete>
      </div>
    </div>
    <div class="row mb-3">
      <label for="subDistrict" class="col-xl-2 col-form-label text-end">ตำบล</label>
      <div class="col-xl-6">
        <subdistrict-autocomplete id="subDistrict" name="subDistrict"
                                  [restrictDistrictCode]="param.district?.code"
                                  [(ngModel)]="param.subDistrict"></subdistrict-autocomplete>
      </div>
    </div>

    <div class="row mb-3">
      <label for="hospital" class="col-xl-2 col-form-label text-end">รหัส/ชื่อ</label>
      <div class="col-xl-6">
        <input type="text" class="form-control" id="hospital" name="hospital" [(ngModel)]="param.hcodeOrHname">
      </div>
    </div>

    <div class="row mb-3">
      <div class="offset-xl-2 col-auto">
        <button type="button" class="btn btn-default btn-custom" (click)="search()">
          <img src="assets/icons/search.png" alt="" class="me-2" height="20">
          ค้นหา
        </button>
        <button type="reset" class="btn btn-default btn-custom ms-2">
          <i class="bi bi-eraser-fill text-muted fw-bolder me-2"></i>
          เคลียร์
        </button>
      </div>
    </div>

    <div class="row mb-3">
      <div class="table-responsive mt-3">
        <table class="table table-sm caption-top border">
          <caption>
            แสดง {{ (pageData?.content?.length ? (pageData?.number * pageData?.size) + 1 : 0) | number }}
            ถึง {{ ((pageData?.number) * pageData?.size + pageData?.content?.length) | number }}
            จากทั้งหมด {{ pageData?.totalElements | number }} รายการ
          </caption>
          <thead class="color-black font-weight-light">
          <tr class="table-light text-center" style="background-color: #eeeeee;">
            <th scope="col">
              <ng-container *ngIf="pageData?.content?.length">
                <input (change)="checkboxAllChange()"
                       [(ngModel)]="checkboxAll"
                       type="checkbox"
                       class="form-check-input  table-checkbox  "
                       id="checkall"
                       name="checkall">
              </ng-container>
            </th>
            <th scope="col" class="w-100px">ลำดับ</th>
            <th scope="col">รหัส/ชื่อ</th>
            <th scope="col">ตำบล</th>
            <th scope="col">อำเภอ</th>
            <th scope="col">จังหวัด</th>
          </tr>
          </thead>
          <tbody class="table-group-divider">
          <tr *ngIf="!pageData?.content?.length">
            <td class="text-muted text-center" colspan="5">
              <i class="bi bi-inbox"></i><br>
              ไม่พบข้อมูล
            </td>
          </tr>
          <tr *ngFor="let item of pageData?.content; let i= index">
            <td class="text-center" nowrap>
              <input type="checkbox"
                     class="form-check-input table-checkbox"
                     [disabled]="item['disable']"
                     id="publish-{{i}}"
                     name="publish-{{i}}"
                     (change)="onChecked()"
                     [(ngModel)]="item['checked']">
            </td>
            <td class="text-center">{{ (pageData.size * pageData.number) + (i + 1) }}</td>
            <td class="text-start">{{item.hcode}} : {{ item.displayHname }}</td>
            <td class="text-start">{{ item.catm.subDistrictName }}</td>
            <td class="text-start">{{ item.catm.districtName }}</td>
            <td class="text-start">{{ item.catm.provinceName }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col-12">
          <ngb-pagination class="d-flex justify-content-center"
                          [maxSize]="5"
                          [rotate]="true"
                          [ellipses]="false"
                          [boundaryLinks]="true"
                          [page]="pageData?.number + 1"
                          (pageChange)="search($event)"
                          [pageSize]="pageData?.size"
                          [collectionSize]="pageData?.totalElements"></ngb-pagination>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12 text-center">
        <button type="submit" class="btn btn-primary btn-custom me-2">
          ตกลง
        </button>
        <button type="button" (click)="activeModal.dismiss()" class="btn btn-default2 btn-custom">
          ยกเลิก
        </button>
      </div>
    </div>
  </div>
</form>
