import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppSecurityModule} from "../security/app-security.module";
import {SharedCommonModule} from './commons/shared-common.module';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {ClipboardModule} from "ngx-clipboard";
import {DigitOnlyModule} from "@uiowa/digit-only";
import {CustomErrorComponent} from "./commons/custom-error.component";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppSecurityModule,
    NgSelectModule,
    ClipboardModule,
    DigitOnlyModule,
    SharedCommonModule,
    CustomErrorComponent
  ],
  declarations: [],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    SharedCommonModule
  ],
  providers: []
})
export class SharedModule {
}
