import {Component, inject, OnInit} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {
  ProvinceAutocompleteComponent
} from "../../../shared/components/province-autocomplete/province-autocomplete.component";
import {SharedModule} from "../../../shared/shared.module";
import {
  TransferHmainOpAutocompleteComponent
} from "../detail/components/transfer-hmain-op-autocomplete/transfer-hmain-op-autocomplete.component";
import {
  HospitalTransferSearchParam, HospitalTransferYearDto,
  HospitalTransferYearParam,
  Page,
  ZoneDto
} from "../../../../generated-model/model";
import {HospitalTransferYearService} from "../services/hospital-transfer-year.service";
import {getFiscalYear, PageRequest} from "../../../app.constants";
import {InboxService} from "../../inbox/inbox.service";
import {HsubModalDialogService} from "../../province-transfer/components/hsub-modal/hsub-modal-dialog.service";
import {filter} from "rxjs/operators";
import {ConfirmDialogService} from "../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {HospitalTransferYearManagerService} from "./hospital-transfer-year-manager.service";
import {ToastService} from "../../../shared/commons/toast/toast-service";

@Component({
  selector: 'app-manager',
  standalone: true,
  imports: [
    FormsModule,
    NgForOf,
    NgIf,
    ProvinceAutocompleteComponent,
    SharedModule,
    TransferHmainOpAutocompleteComponent,
    DecimalPipe
  ],
  templateUrl: './manager.component.html',
  styleUrl: './manager.component.scss'
})
export class ManagerComponent implements OnInit{
  param: HospitalTransferSearchParam = {}
  hospitalTransferYearService = inject(HospitalTransferYearService);
  years: number[] = []
  zones: ZoneDto[] = []
  inboxService: InboxService = inject(InboxService);
  pageData: Page<HospitalTransferYearDto> = {number: 0, size: 10, totalElements: 0};
  hsubModalDialogService: HsubModalDialogService = inject(HsubModalDialogService);
  confirmDialogService = inject(ConfirmDialogService);
  hospitalTransferYearManagerService = inject(HospitalTransferYearManagerService);
  toastService: ToastService = inject(ToastService);

  ngOnInit(): void {
    this.hospitalTransferYearService.years$().subscribe(years => {
      this.years = years
      if (this.years?.includes(getFiscalYear())) {
        this.param.year = getFiscalYear()
      }
    })

    this.inboxService.findAllZone().subscribe(zones => {
      this.zones = zones;
    });
  }

  selectYear() {
    delete this.param.hospitalTransferYearDto
  }

  search(page: number = 1) {
    const pageRequest: PageRequest = {page: page - 1, size: this.pageData.size}
    let searchParam: HospitalTransferYearParam = {
      zoneId: this.param?.zone?.id,
      provinceId :  this.param?.province?.provinceId,
      hmainOpCode: this.param?.hospitalTransferYearDto?.hmainOp?.hcode
    }
    this.hospitalTransferYearService.search(this.param.year, searchParam, pageRequest).subscribe({
      next: e => {
        this.pageData = e
      }
    })
  }

  clearForm() {
    this.param = {year: getFiscalYear()}
  }

  edit(item: HospitalTransferYearDto) {
    this.hsubModalDialogService.edit(item.hmainOp.hcode, item.year)

  }

  delete(item: HospitalTransferYearDto) {
    this.confirmDialogService.open({content: `ยืนยันการลบข้อมูล <span class="text-danger fw-bold">เมื่อลบข้อมูลแม่ข่าย ข้อมูลลูกข่ายจะถูกลบทั้งหมด</span>`, title: 'แจ้งเตือน', isInnerHTML : true}).pipe(filter(e => !!e))
      .subscribe({
        next: e => {
          this.hospitalTransferYearManagerService.delete(item.id).subscribe({
            next: e => {
              this.toastService.showSuccess('ลบข้อมูลสำเร็จ')
              this.pageData.content = this.pageData.content.filter(h => h.id !== item.id)
            }
          })
        }
      });
  }
}
