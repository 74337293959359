<ng-select [id]="id"
           [name]="name"
           [items]="subDistricts"
           placeholder="กรุณาเลือก"
           class="novalidate"
           bindLabel="name"
           [disabled]="disabled"
           [clearable]="true"
           [compareWith]="compareWithCode"
           [editableSearchTerm]="true"
           [loading]="typeaheadLoading"
           (ngModelChange)="valueChange($event)"
           #ngSelectComponent
           [(ngModel)]="value">
</ng-select>
