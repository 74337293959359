import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {HsubModalComponent} from "./hsub-modal.component";

@Injectable({providedIn: 'root'})
export class HsubModalDialogService {

  constructor(private modalService: NgbModal) {
  }

  view(hmainOpCode: string, year : number) {
    const modalRef: NgbModalRef = this.modalService.open(HsubModalComponent, {
      size: "lg",
    });
    const component: HsubModalComponent = modalRef.componentInstance
    component.crudMode = 'READ'
    component.hmainOpCode = hmainOpCode
    component.year = year
  }

  edit(hmainOpCode: string, year : number) {
    const modalRef: NgbModalRef = this.modalService.open(HsubModalComponent, {
      size: "lg",
    });
    const component: HsubModalComponent = modalRef.componentInstance
    component.crudMode = 'UPDATE'
    component.hmainOpCode = hmainOpCode
    component.year = year
  }
}
