import {Component, inject, OnInit} from '@angular/core';
import {
  DistrictAutocompleteComponent
} from "../../../../../shared/components/district-autocomplete/district-autocomplete.component";
import {FormsModule} from "@angular/forms";
import {
  HospitalAutocompleteComponent
} from "../../../../../shared/components/hospital-autocomplete/hospital-autocomplete.component";
import {
  ProvinceAutocompleteComponent
} from "../../../../../shared/components/province-autocomplete/province-autocomplete.component";
import {
  SubdistrictAutocompleteComponent
} from "../../../../../shared/components/subdistrict-autocomplete/subdistrict-autocomplete.component";
import {HospitalDto, Page, TransferHsubSearchRequest, ZoneDto} from "../../../../../../generated-model/model";
import {CRUD, PageRequest} from "../../../../../app.constants";
import {HospitalHsubService} from "../../../services/hospital-hsub.service";
import {NgbActiveModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {SecurityAuthorityService} from "../../../../../security/security-authority.service";
import {CustomUserDataResult} from "../../../../../openid-connect-userinfo";
import {InboxService} from "../../../../inbox/inbox.service";

@Component({
  selector: 'app-transfer-hsub-modal',
  standalone: true,
  imports: [
    DistrictAutocompleteComponent,
    FormsModule,
    HospitalAutocompleteComponent,
    ProvinceAutocompleteComponent,
    SubdistrictAutocompleteComponent,
    DecimalPipe,
    NgbPagination,
    NgForOf,
    NgIf
  ],
  templateUrl: './transfer-hsub-modal.component.html',
  styleUrl: './transfer-hsub-modal.component.scss'
})
export class TransferHsubModalComponent implements OnInit{
  exists: HospitalDto[] = []
  crudMode: CRUD = 'CREATE'
  param: TransferHsubSearchRequest = {}
  pageData: Page<HospitalDto> = {number: 0, size: 10};
  checkboxAll: boolean;
  hospitalHsubService = inject(HospitalHsubService);
  public activeModal = inject(NgbActiveModal);
  private _oidcSecurityService = inject(OidcSecurityService);
  private securityAuthorityService = inject(SecurityAuthorityService);
  private inboxService: InboxService = inject(InboxService);
  zones: ZoneDto[] = []

  ngOnInit(): void {
    this.inboxService.findAllZone().subscribe(zones => {
      this.zones = zones;
      this.filterZonesByUserOrganization();
    });
  }

  filterZonesByUserOrganization() {
    this._oidcSecurityService.userData$.subscribe((userdata: CustomUserDataResult) => {
      this.securityAuthorityService.hasAnyAuthority(['zone']).subscribe(hasAuthority => {
        if (hasAuthority) {
          const userOrganizationId = userdata?.userData?.organization?.id?.padStart(2, "0");
          this.zones = this.zones.filter(zone => zone.id === userOrganizationId);
          this.param.zone = this.zones[0];
        }
      });
    });
  }

  search(page: number = 1) {
    const pageRequest: PageRequest = {page: page - 1, size: this.pageData.size, sort: 'hcode,asc'}
    this.hospitalHsubService.search(this.param, pageRequest)
      .subscribe(e => {
        this.pageData = e;
        this.checkboxAll = false;
        this.pageData?.content?.forEach(e => {
          e['disable'] = this.exists?.find(f => f.hcode === e.hcode);
          e['checked'] = this.exists?.find(f => f.hcode === e.hcode);
        });
      })
  }

  onChecked() {
    this.checkboxAll = this.pageData?.content
      .every(e => e['checked']);
  }

  checkboxAllChange() {
    this.pageData?.content?.forEach(e => {
      if (!e['disable']) {
        e['checked'] = this.checkboxAll;
      }
    });
  }

  submit() {
    let hospitalDtos = this.pageData?.content?.filter(f => f['checked']);
    hospitalDtos = hospitalDtos?.filter(f => !this.exists?.some(s => s.hcode === f.hcode))
    this.activeModal.close(hospitalDtos)
  }


}
