import {Component, inject, OnInit} from '@angular/core';
import {CRUD, PageRequest} from "../../../../app.constants";
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {
  DistrictAutocompleteComponent
} from "../../../../shared/components/district-autocomplete/district-autocomplete.component";
import {FormsModule} from "@angular/forms";
import {NgbActiveModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {
  ProvinceAutocompleteComponent
} from "../../../../shared/components/province-autocomplete/province-autocomplete.component";
import {SharedModule} from "../../../../shared/shared.module";
import {
  SubdistrictAutocompleteComponent
} from "../../../../shared/components/subdistrict-autocomplete/subdistrict-autocomplete.component";
import {HospitalTransferYearService} from "../../../hospital-transfer/services/hospital-transfer-year.service";
import {HospitalDto, HospitalTransferYearDto, HospitalTransferYearParam} from "../../../../../generated-model/model";
import {ConfirmDialogService} from "../../../../shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {filter} from "rxjs/operators";
import {ToastService} from "../../../../shared/commons/toast/toast-service";
import {
  HospitalTransferYearManagerService
} from "../../../hospital-transfer/manager/hospital-transfer-year-manager.service";

@Component({
  selector: 'app-hsub-modal',
  standalone: true,
  imports: [
    DecimalPipe,
    DistrictAutocompleteComponent,
    FormsModule,
    NgForOf,
    NgIf,
    NgbPagination,
    ProvinceAutocompleteComponent,
    SharedModule,
    SubdistrictAutocompleteComponent
  ],
  templateUrl: './hsub-modal.component.html',
  styleUrl: './hsub-modal.component.scss'
})
export class HsubModalComponent implements OnInit {
  crudMode: CRUD = 'READ'
  hmainOpCode: string
  year: number
  public activeModal = inject(NgbActiveModal);
  hospitalTransferYearService = inject(HospitalTransferYearService);
  data: HospitalTransferYearDto = {}
  confirmDialogService = inject(ConfirmDialogService);
  toastService: ToastService = inject(ToastService);
  hospitalTransferYearManagerService = inject(HospitalTransferYearManagerService);

  ngOnInit(): void {
    const pageRequest: PageRequest = {size: 10}
    let searchParam: HospitalTransferYearParam = {
      hmainOpCode: this.hmainOpCode
    }
    this.hospitalTransferYearService.search(this.year, searchParam, pageRequest).subscribe(value => {
      // if search via hmainOpCode then value.content is not null and size === 1 always
      this.data = value.content.flatMap(m => m)[0]
    })
  }

  delete(item: HospitalDto) {
    this.confirmDialogService.open({content: `ยืนยันการลบข้อมูล <span class="text-danger fw-bold">ข้อมูลกิจกรรมจะถูกลบทั้งหมด</span>`, title: 'แจ้งเตือน', isInnerHTML : true}).pipe(filter(e => !!e))
      .subscribe({
        next: e => {
          this.hospitalTransferYearManagerService.deleteHsub(this.data.id, item.hcode).subscribe({
            next: e => {
              this.toastService.showSuccess('ลบข้อมูลสำเร็จ')
              this.data.hsubs = this.data.hsubs.filter(h => h.hcode !== item.hcode)
            }
          })
        }
      });
  }
}
